import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NotLoggedIn from '../styles/NotLoggedIn';
import CircularProgress from "@mui/material/CircularProgress";

const ProtectedStudentRoute = ({ element: Component }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, { withCredentials: true })
      .then((response) => {
        if (response.data.user.userType === "student") {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch(() => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // If still loading, show loader
  if (loading) {
    return (
      <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
        <CircularProgress style={{ color: "blue" }} />
      </div>
    );
  }

  // If not authenticated, redirect to login page
  if (!isAuthenticated) {
    return (
        <NotLoggedIn></NotLoggedIn>
    )
  }

  // If authenticated, render the protected component
  return Component;
};

export default ProtectedStudentRoute;
