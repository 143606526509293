import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Navbar from "../styles/Navbar";
import CourseCard from "./CourseCard";
import Footer from "../LandingPage/Footer";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, Link } from "react-router-dom";

const CoursesPage = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location?.state?.activeBtn ? location.state.activeBtn : "courses"
  );
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const [fetchedSimulators, setFetchedSimulators] = useState([]);
  const [loading, setLoading] = useState(true);

  // Typing animation for the heading
  const headingProps = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    config: { duration: 4000 },
    reset: true,
  });
  
  useEffect(() => {
    setActiveTab(
      location?.state?.activeBtn ? location.state.activeBtn : "courses"
    );
  }, [location]);

  // Typing animation for the paragraph
  const paragraphProps = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    config: { duration: 5000 },
    reset: true,
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/courses/getAllCourses`
        );
        if (response) {
          const availableCourses = response.data.courses.filter(course => course.courseStatus); // Only keep courses with courseStatus true
          setLoading(false);
          setFetchedCourses(availableCourses);
        }
      } catch (error) {
        //console.error("Error fetching courses:", error);
      }
    };

    const fetchSimulators = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/courses/getAllSimulators`
        );
        if (response) {
          setLoading(false);
          //console.log(response.data);
          setFetchedSimulators(response.data);
        }
      } catch (error) {
        //console.error("Error fetching simulators:", error);
      }
    };

    fetchCourses();
    fetchSimulators();
  }, []);
  
  const simulatorImages = [
    `${process.env.PUBLIC_URL}/images/Course-img-3.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-4.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-9.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-5.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-6.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-3.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-4.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-1.png`,
  ];
  
  const courseImages = [
    `${process.env.PUBLIC_URL}/images/Course-img-1.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-2.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-5.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-6.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-3.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-9.jpg`,
    `${process.env.PUBLIC_URL}/images/Course-img-2.png`,
    `${process.env.PUBLIC_URL}/images/Course-img-1.png`,
  ];

  // Choose the appropriate images and content based on the active tab
  const contentToDisplay =
    activeTab === "courses" ? fetchedCourses : fetchedSimulators;

  return (
    <div>
      <Navbar textColor="text-blue-800" />

      <div className="relative">
        <img
          src={`${process.env.PUBLIC_URL}/images/Courses-page-main-image.jpg`}
          alt="Landing"
          className="w-full h-[500px] object-cover"
        />

        {/* Overlay text and button */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
          <animated.h1
            style={headingProps}
            className="text-sm md:text-4xl md:h-12 font-bold mb-4 overflow-hidden whitespace-nowrap"
          >
            All Project Management Training Courses
          </animated.h1>

          <animated.p
            style={paragraphProps}
            className="text-xs w-4/5 sm:text-xl mb-8 overflow-hidden whitespace-nowrap"
          >
            Explore comprehensive courses to enhance your project management
            skills.
          </animated.p>

          <Link to="/register">
            <div className="w-full mt-4 text-center font-semibold bg-blue-900 text-white rounded-full px-3 py-2 hover:bg-blue-600 cursor-pointer hover:shadow-lg active:text-white active:bg-blue-500 text-sm truncate">
              Get Started
            </div>
          </Link>
        </div>
      </div>

      {/* Tabs for filtering */}
      <div className="flex flex-wrap justify-center gap-4 my-8 px-4">
        <div
          onClick={() => setActiveTab("courses")}
          className={`cursor-pointer px-4 py-2 rounded-lg ${
            activeTab === "courses" ? "bg-blue-500 text-white" : "bg-gray-200"
          } transition-colors duration-300`}
        >
          Courses
        </div>
        <div
          onClick={() => setActiveTab("simulators")}
          className={`cursor-pointer px-4 py-2 rounded-lg ${
            activeTab === "simulators"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } transition-colors duration-300`}
        >
          Simulators
        </div>
      </div>

      {/* Filtered content */}
      {loading && (
        <div className="flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      )}

      <div className="p-4 px-4 sm:px-8 flex flex-wrap gap-6 justify-center mb-12">
        {contentToDisplay.map((item, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-[23%]"
          >
            <CourseCard
              course={item}
              type={activeTab} // Pass the type to CourseCard
              courseImage={
                activeTab === "courses"
                  ? courseImages[index]
                  : simulatorImages[index]
              }
            />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default CoursesPage;
