import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import CourseCard from "../../Courses/CourseCard";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import { Link } from "react-router-dom";

const InstructorCourses = () => {
  const [courses, setCourses] = useState([]);
  const [simulators, setSimulators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState("courses");

  useEffect(() => {
    if (viewType === "courses") {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/courses/getAllCourses`, {
          withCredentials: true,
        })
        .then((response) => {
          //console.log(response);
          setCourses(
            Array.isArray(response.data.courses) ? response.data.courses : []
          );
        })
        .catch((error) => {
          //console.error("Error fetching courses:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if ( viewType === "simulators") {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/courses/getAllSimulators`, {
          withCredentials: true,
        })
        .then((response) => {
          //console.log(response);
          setSimulators(Array.isArray(response.data) ? response.data : []);
        })
        .catch((error) => {
          //console.error("Error fetching simulators:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [viewType]);

  return(

    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full md:mt-0 relative ">
        <div className="w-full p-4 md:p-6 ">
          <PageNameAndDate pageName={"Courses"} />

          <div className="flex flex-col sm:flex-row w-full my-3 items-center ">
          {loading && (
            <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )}
  {/* Center buttons */}
  <div className="flex gap-4 sm:gap-6 justify-center flex-grow mb-4 sm:mb-0">
    <div
      onClick={() => setViewType("courses")}
      className={`cursor-pointer px-4 py-2 rounded-lg sm:ml-8 ${
        viewType === "courses" ? "bg-blue-500 text-white" : "bg-gray-200"
      } transition-colors duration-300`}
    >
      Courses
    </div>
    <div
      onClick={() => setViewType("simulators")}
      className={`cursor-pointer px-4 py-2 rounded-lg ${
        viewType === "simulators" ? "bg-blue-500 text-white" : "bg-gray-200"
      } transition-colors duration-300`}
    >
      Simulators
    </div>
  </div>
 
  {/* Right-aligned button */}
  <Link
    className="md:ml-auto md:mt-0 mt-2"
    to={
      viewType === "simulators"
        ? "/instructor/dashboard/courses/addSimulator"
        : "/instructor/dashboard/courses/addCourse"
    }
  >
    <button
      className="bg-blue-500 hover:bg-blue-700 hover:shadow-lg active:bg-white active:text-blue-800 text-white py-2 px-4 rounded-full w-full sm:w-auto"
    >
      {viewType === "simulators" ? "Add Simulator" : "Add Course"}
    </button>
  </Link>
</div>


        </div>

        <div className="flex justify-center gap-8 h-full w-full p-4 flex-wrap">
          {viewType === "courses" ? (
            courses.length > 0 ? (
              courses.map((course, index) => (
                <div key={index} className="w-72">
                  <CourseCard
                    Id={index + 1}
                    courseTitle={course.title}
                    duration={course.duration}
                    mode={course.mode}
                    courseId={course.id}
                    userType="instructor"
                    type='course'
                   
                  />
                </div>
              ))
            ) : (
              <p>No courses available</p>
            )
          ) : viewType === "simulators" ? (
            simulators.length > 0 ? (
              simulators.map((simulator, index) => (
                <div key={index} className="w-72">
                  <CourseCard
                    Id={index + 1}
                    courseTitle={simulator.title}
                    duration={simulator.duration}
                    mode={simulator.noOfModules + " Module"}
                    courseId={simulator.id}
                    userType="instructor"
                    type='simulator'
                    
                  />
                </div>
              ))
            ) : (
              <p>No simulators available</p>
            )
          ) : null}
        </div>
      </div>
    </div>

  );
};

export default InstructorCourses;
