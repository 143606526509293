import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCookies } from "react-cookie";
import Cart from "./Cart"; // Import the Cart component
import axios from "axios";
import { HashLink } from "react-router-hash-link";

const Navbar = ({ textColor = "text-white" }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["jwt", "PMI-cart"]);
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Start with null to indicate loading state
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false); // State to manage cart visibility
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility

  const location = useLocation(); // Get the current route

  const cartItemsCount = (cookies["PMI-cart"] || []).length; // Calculate number of items in the cart

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, {
        withCredentials: true,
      })
      .then((response) => {
        setIsAuthenticated(true);
        //console.log(response);
      })
      .catch((error) => {
        setIsAuthenticated(false);
        //console.log(error);
      });
  }, []);

  let userType;
  if (cookies.userType) {
    if (cookies.userType === "student") {
      userType = "student";
    } else {
      userType = "instructor";
    }
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const loginBgColor =
    textColor === "text-blue-800" ? "bg-blue-800" : "bg-white";
  const loginTextColor =
    textColor === "text-blue-800" ? "text-white" : "text-blue-800";
  const loginHoverColor =
    textColor === "text-blue-800" ? "hover:bg-blue-400" : "hover:bg-gray-300";

  // Add active styling class if the current path matches the link
  const getActiveClass = (path) =>
    location.pathname === path ? "underline" : "";
  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  const handleLogout = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/auth/logout`,
        {
          withCredentials: true,
        }
      );

      if (response) {
        removeCookie("userName", { path: "/" });
        removeCookie("userType", { path: "/" });

        // Refresh the page after logout
        window.location.reload();
      }
    } catch (error) {
      //console.error("Error during logout:", error);
    }
  };

  return (
    <div className={textColor}>
      <nav className="flex items-center justify-between p-4 h-16">
        {/* <Link to="/">
          <div className={`text-xl 2xl:text-2xl font-bold ${textColor}`}>PMIAcademy</div>
        </Link> */}
        <Link to="/">
      <div className="flex gap-2">
        {/* <img
          src={`${process.env.PUBLIC_URL}/images/logo2.png`} 
          alt="PMIAcademy Logo"
          className="w-10 2xl:w-12 " // You can adjust the width and height as per your design
        /> */}
        <p className={`text-xl self-center 2xl:text-2xl font-semibold ${textColor}`}>MndE Consultants</p>
      </div>
    </Link>

        {/* Centered navigation items on larger screens */}
        <div
          className={`hidden md:flex justify-center flex-grow space-x-8 ml-4 2xl:text-lg ${textColor}`}
        >
          <Link to="/" className={`hover:text-blue-200 ${getActiveClass("/")}`}>
            Home
          </Link>
          <Link
            to="/courses"
            state={{ activeBtn: "courses" }}
            className={`hover:text-blue-200 ${getActiveClass("/courses")}`}
          >
            Courses
          </Link>
          <Link
            to="/simulators"
            state={{ activeBtn: "simulators" }}
            className={`hover:text-blue-200 ${getActiveClass("/simulators")}`}
          >
            Simulator
          </Link>
          <HashLink
            scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
            to="/#contact-us"
          >
            Contact Us
          </HashLink>
          <Link
            to="/About-us"
            className={`hover:text-blue-200 ${getActiveClass("/about")}`}
          >
            About Us
          </Link>
        </div>

        {/* Conditional buttons */}
        <div className="hidden md:flex items-center space-x-4">
          {isAuthenticated === null ? null : isAuthenticated ? (
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`w-32 2xl:w-40 ${textColor} 2xl:text-lg  py-1.5 pr-4 overflow-hidden cursor-pointer text-center whitespace-nowrap text-ellipsis`}
              >
                {cookies.userName || ""}
              </div>

              {showDropdown && (
                <div className="absolute top-full 2xl:text-lg z-50 right-0 bg-white text-blue-800 shadow-lg rounded-lg">
                  <Link
                    to={`/${userType}/dashboard`}
                    className="block px-4 py-2 hover:bg-gray-200"
                  >
                    Dashboard
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link to="/login">
                <div
                  className={`w-24 ${loginBgColor} ${loginTextColor} rounded-full px-2 py-1.5 ${loginHoverColor} cursor-pointer text-center`}
                >
                  Login
                </div>
              </Link>
              <Link to="/register">
                <div className="w-24 bg-blue-800 text-white rounded-full px-2 py-1.5 hover:bg-blue-500 cursor-pointer text-center">
                  Sign Up
                </div>
              </Link>
            </>
          )}
          <div className="relative">
            <button onClick={toggleCart} className="focus:outline-none">
              <ShoppingCartIcon className={textColor} />
              {cartItemsCount > 0 && (
                <span className="absolute left-4 bottom-5 h-5 w-5 bg-blue-800 text-white rounded-full flex items-center justify-center text-xs">
                  {cartItemsCount}
                </span>
              )}
            </button>
          </div>
        </div>

        {/* Hamburger menu and Cart icon for small screens */}
        <div className="md:hidden flex items-center space-x-4">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isOpen ? (
              <CloseIcon className={textColor} />
            ) : (
              <MenuIcon className={textColor} />
            )}
          </button>
          <div className="relative">
            <button onClick={toggleCart} className="focus:outline-none">
              <ShoppingCartIcon className={textColor} />
              {cartItemsCount > 0 && (
                <span className="absolute top-0 right-0 h-5 w-5 bg-red-500 text-white rounded-full flex items-center justify-center text-xs">
                  {cartItemsCount}
                </span>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Separate div for mobile menu */}
      {isOpen && (
        <div
          className={`md:hidden bg-gradient-to-b from-[#152354] via-[#1B2D6B] to-[#2F4DBA] p-4 text-white`}
        >
          <Link
            to="/"
            className={`block py-2 hover:text-blue-200 ${getActiveClass("/")}`}
          >
            Home
          </Link>
          <Link
            to="/courses"
            state={{ activeBtn: "courses" }}
            className={`block py-2 hover:text-blue-200 ${getActiveClass(
              "/courses"
            )}`}
          >
            Courses
          </Link>
          <Link
            to="/simulators"
            state={{ activeBtn: "simulators" }}
            className={`block py-2 hover:text-blue-200 ${getActiveClass(
              "/simulator"
            )}`}
          >
            Simulator
          </Link>

          <HashLink
            scroll={(el) => el.scrollIntoView({ behavior: "auto" })}
            to="/#contact-us"
            className={`block py-2 hover:text-blue-200 ${getActiveClass(
              "/simulator"
            )}`}
          >
            Contact Us
          </HashLink>
          <Link
            to="/About-us"
            className={`block py-2 hover:text-blue-200 ${getActiveClass(
              "/about"
            )}`}
          >
            About Us
          </Link>
          <div className="flex space-x-4 mt-4">
            {isAuthenticated === null ? null : isAuthenticated ? (
              <div
  className="relative"
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
>
  <div
    className={`w-32 text-white py-1.5 pr-4 overflow-hidden cursor-pointer text-center whitespace-nowrap text-ellipsis`}
  >
    {cookies.userName || ""}
  </div>

  {showDropdown && (
    <div
      className="absolute top-full right-0 bg-white text-blue-800 shadow-lg rounded-lg z-50"
    >
      <Link
        to={`/${userType}/dashboard`}
        className="block px-4 py-2 hover:bg-gray-200"
      >
        Dashboard
      </Link>
      <button
        onClick={handleLogout}
        className="block px-4 py-2 w-full text-left hover:bg-gray-200"
      >
        Logout
      </button>
    </div>
  )}
</div>

            ) : (
              <>
                <Link to="/login">
                  <div
                    className={`w-24 bg-white text-blue-800 rounded-full px-2 py-1.5 ${loginHoverColor} cursor-pointer text-center`}
                  >
                    Login
                  </div>
                </Link>
                <Link to="/register">
                  <div className="w-24 bg-white text-blue-800 rounded-full px-2 py-1.5 hover:bg-blue-500 cursor-pointer text-center">
                    Sign Up
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      )}

      {/* Cart component */}
      {isCartOpen && (
        <div>
          <Cart cookies={cookies} setCookie={setCookie} closeCart={closeCart} />
        </div>
      )}
    </div>
  );
};

export default Navbar;
