import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import { useParams, Link } from "react-router-dom";
import { format, addWeeks, isSameDay, parseISO } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Calendar default styles
import EditIcon from "@mui/icons-material/Edit"; // Import Material UI edit icon
import DeleteIcon from "@mui/icons-material/Delete";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import Modal from "../../../styles/Modal";
import ConfirmDialog from './utilities/Dialog'

const InstructorCourseDetail = () => {
  const [loading, setLoading] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [timeError, setTimeError] = useState("");
  const [studentsCount, setStudentsCount] = useState(null);
  const [meetingError, setMeetingError] = useState("");
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [modalTitle, setModalTitle] = useState(""); // State for modal title
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
const [startDateToDelete, setStartDateToDelete] = useState(null); // To store the selected start date for deletion
  const [isEditable, setIsEditable] = useState({
    title: false,
    price: false,
    duration: false,
  });
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    duration: "",
    startDates: [],
    classTimes: [],
    meetingLink,
  });
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedClassDate, setSelectedClassDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStartDate, setNewStartDate] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [timeData, setTimeData] = useState("");

  const params = useParams();
 
 
  const fetchCourseDetails = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/getCourseDetail/${params.id}`,
        { withCredentials: true }
      )
      .then((response) => {
        const course = response.data.course;
        setFormData({
          title: course.title,
          description: course.description,
          price: course.price,
          duration: course.duration,
          startDates: course.startingDates || [],
          classTimes:
            course.startingDates.flatMap((date) => date.classTimes) || [],
        });
      })
      .catch((error) => {
        //console.error("Error fetching course details:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  useEffect(() => {
   
      fetchCourseDetails();
    
  }, [params.id]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setUnsavedChanges(true);
  };
  const toggleEdit = (field) => {
    setIsEditable((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleMeetingLinkChange = (e) => {
    setMeetingError("");
    setMeetingLink(e.target.value);
    ////console.log(e.target.value);
  };
  const handleAddMeetingLink = () => {
  
    // Use the URL constructor for validation
    let url;
    try {
      url = new URL(meetingLink); // This will throw an error if the meetingLink is invalid
    } catch (_) {
      setMeetingError("Please enter a valid link");
      return;
    }
  
    // Ensure the URL has either http or https protocol
    if (url.protocol !== "http:" && url.protocol !== "https:") {
      setMeetingError("Please enter a valid link with http or https");
      return;
    }
  
    // Proceed with backend request since the URL is valid
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/addMeetingLink/${params.id}`,
        {
          meetingLink,
          selectedStartDate, // Send the selectedStartDate in the request body
        },
        { withCredentials: true }
      )
      .then((response) => {
        setLoading(false);
        setModalTitle("Success");
        setModalMessage("Meeting link added successfully");
        setModalVisible(true); 
        ////console.log("Meeting link added successfully:", response.data);
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error adding meeting link:", error);
      });
  };
  

  const handleStartDateChange = (e) => {
    setMeetingLink("");
    const selectedDate = new Date(e.target.value);
    setSelectedStartDate(selectedDate);

    // Filter class times for the selected start date
    const selectedStartDateObj = formData.startDates.find(
      (d) =>
        format(new Date(d.startDate), "yyyy-MM-dd") ===
        format(selectedDate, "yyyy-MM-dd")
    );
    setMeetingLink(
      selectedStartDateObj.meetingLink ? selectedStartDateObj.meetingLink : ""
    );

    setFormData({
      ...formData,
      classTimes: selectedStartDateObj ? selectedStartDateObj.classTimes : [],
    });
  };

  const handleClassDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setSelectedClassDate(formattedDate);
  };

  const handleClassTimeChange = (id, field, value) => {
    setTimeError("");
    if (id) {
      const newClassTimes = formData.classTimes.map((classTime) => {
        if (classTime._id === id) {
          return { ...classTime, [field]: value };
        }
        return classTime;
      });

      // Update formData state
      setFormData({ ...formData, classTimes: newClassTimes });
    }

    // Update timeData state
    setTimeData((prevTimeData) => {
      const currentData = prevTimeData[0] || {}; // Get the current data or initialize an empty object
      return [
        {
          selectedStartDate: selectedStartDate,
          selectedClassDate: selectedClassDate,
          startTime: field === "startTime" ? value : currentData.startTime,
          endTime: field === "endTime" ? value : currentData.endTime,
        },
      ];
    });
  };

  const removeClassTime = (id) => {
    ////console.log(id, selectedStartDate);

    if (id && selectedStartDate) {
      setLoading(true);
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/deleteClassTime/${params.id}/${id}`,
          {
            data: { selectedStartDate }, // Send selectedStartDate in request body
            withCredentials: true,
          }
        )
        .then((response) => {
          setLoading(false);
          fetchCourseDetails();
          setModalTitle("Success");
          setModalMessage("Class time deleted successfully");
          setModalVisible(true); 
          ////console.log("Class time deleted successfully:", response.data);

          // After successful deletion, remove the class time from the state
          const updatedClassTimes = formData.classTimes.filter(
            (classTime) => classTime._id !== id
          );
          setFormData({ ...formData, classTimes: updatedClassTimes });
        })
        .catch((error) => {
          setLoading(false);
          //console.error("Error deleting class time:", error);
        });
    }
  };

  useEffect(() => {
    const getStudentCount = async () => {
      if (selectedStartDate) {
        try {
          // Fetch student count based on selectedStartDate and courseId
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/students/getStudentsCount`,
            {
              params: {
                courseId: params.id,
                startDate: selectedStartDate,
              },
              withCredentials: true,
            }
          );
          setStudentsCount(response.data.count);

          // Handle the response here
          // Update state or perform actions with response data as needed
        } catch (error) {
          //console.error("Error fetching student count:", error);
          // Handle error as needed (e.g., set an error state)
        }
      }
    };

    // Call the function to fetch student count
    getStudentCount();
  }, [selectedStartDate]);

  const handleSaveClassTimes = () => {
    const classTimes = formData.classTimes;

    const existingDate = classTimes.find((dateObj) => {
      return isSameDay(parseISO(dateObj.date), selectedClassDate);
    });
    ////console.log(existingDate);

    if (!existingDate) {
      ////console.log(timeData)
      ////console.log("not existing date");
      if (!timeData[0]?.startTime || !timeData[0]?.endTime) {
        setTimeError("Please select both start time and end time.");
        return;
      }
    }
    // if (classTimes.some((time) => !time.startTime || !time.endTime)) {
    //   setTimeError('Please select both start time and end time.');
    //   return;
    // }

    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/updateClassTimes/${params.id}`,
        { classTimes: timeData },
        { withCredentials: true }
      )
      .then((response) => {
        fetchCourseDetails();
        setModalTitle("Success");
        setModalMessage("Class time added successfully");
        setModalVisible(true); 
      })
      .catch((error) => {
        //console.error("Error updating class times:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleSave = () => {
    const updatedFormData = {
      ...formData,
    };
    

    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/updateCourseDetail/${params.id}`,
        updatedFormData,
        { withCredentials: true }
      )
      .then((response) => {
        setUnsavedChanges(false);
      })
      .catch((error) => {
        //console.error("Error updating course:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDeleteStartDate = (startDate) => {
    // Find the start date object in formData
    const selectedStartDateObj = formData.startDates.find(
      (date) =>
        format(new Date(date.startDate), "yyyy-MM-dd") ===
        format(new Date(startDate), "yyyy-MM-dd")
    );
  
    // Check if classTimes array exists and is not empty
    if (
      selectedStartDateObj &&
      selectedStartDateObj.classTimes &&
      selectedStartDateObj.classTimes.length > 0
    ) {
      setModalTitle("Error");
      setModalMessage("This start date cannot be deleted because it has scheduled classes.");
      setModalVisible(true); 
      return;
    }
  
    // Store the start date to be deleted and open the confirmation dialog
    setStartDateToDelete(startDate);
    setConfirmDialogOpen(true);
  };
  
  // Function to handle the confirmation of deletion
  const confirmDelete = () => {
    setConfirmDialogOpen(false);
    setLoading(true);
  
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/deleteStartDate/${params.id}`,
        {
          data: { startDate: startDateToDelete }, // Pass startDateToDelete in the request body
          withCredentials: true,
        }
      )
      .then((response) => {
        setLoading(false);
        fetchCourseDetails();
        setModalTitle("Success");
        setModalMessage("Start date deleted successfully");
        setModalVisible(true); 
        ////console.log("Start date deleted successfully:", response.data);
  
        // Update the formData to remove the deleted start date
        const updatedStartDates = formData.startDates.filter(
          (date) =>
            format(new Date(date.startDate), "yyyy-MM-dd") !==
            format(new Date(startDateToDelete), "yyyy-MM-dd")
        );
        setFormData({ ...formData, startDates: updatedStartDates });
  
        // Clear selected start date if the deleted date was selected
        if (
          format(new Date(startDateToDelete), "yyyy-MM-dd") ===
          format(new Date(selectedStartDate), "yyyy-MM-dd")
        ) {
          setSelectedStartDate(null);
          setMeetingLink(""); // Clear the meeting link
        }
      })
      .catch((error) => {
        setLoading(false);
        setModalTitle("Error");
        setModalMessage("Error deleting start date");
        setModalVisible(true); 
        //console.error("Error deleting start date:", error);
      });
  };
  
  // Function to cancel the deletion
  const cancelDelete = () => {
    setConfirmDialogOpen(false);
  };
  

  const handleSaveStartDate = () => {
    if (newStartDate) {
        // Create a Date object from the selected date
        const formattedDate = new Date(newStartDate);
        
        // Set hours to midnight (00:00:00) local time
        formattedDate.setHours(0, 0, 0, 0);
        
        // Extract the components of the date
        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(formattedDate.getDate()).padStart(2, '0');

        // Construct the final formatted date string with +00:00
        const finalFormattedDate = `${year}-${month}-${day}T00:00:00.000+00:00`;
        
        // Log the final formatted date for debugging
        ////console.log(finalFormattedDate);
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/addStartDate/${params.id}`,
                { startDate: finalFormattedDate }, // Send the formatted date string
                { withCredentials: true }
            )
            .then((response) => {
                setLoading(false);
                fetchCourseDetails();
                closeModal(); // Close modal after saving
            })
            .catch((error) => {
              setIsModalOpen(false);
              setModalTitle("Error");
              setModalMessage(error.response.data.message);
              setModalVisible(true); // Show the modal
                setLoading(false);
                //console.error("Error adding start date:", error);
            });
    }
};




const formatTimeForInput = (isoString) => {
  // Parse the ISO date string (UTC format)
  const isoFormatted = `${isoString.slice(0, 4)}-${isoString.slice(4, 6)}-${isoString.slice(6, 8)}T${isoString.slice(9, 11)}:${isoString.slice(11, 13)}:${isoString.slice(13, 15)}Z`;

  const date = new Date(isoFormatted); // Create a Date object from the ISO string (interpreted as UTC time)

  // Convert to local time and extract time in HH:MM format
  const localHours = String(date.getHours()).padStart(2, '0');
  const localMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${localHours}:${localMinutes}`; // Returns local time in HH:MM format
};


  const updateTimeForSave = (dateString, timeString) => {
    // Function to get the user's time zone
    const getUserTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const timeZone = getUserTimeZone(); // Get user's time zone
    ////console.log(`User Time Zone: ${timeZone}`);
    
    // Combine date and time into a single string
    const localDateTimeString = `${dateString}T${timeString}`;

    // Create a Date object from the local date and time
    const localDateTime = new Date(localDateTimeString); // Treating as local time

    // Get the user's time zone offset in milliseconds
    const userOffsetInMilliseconds = localDateTime.getTimezoneOffset() * 60 * 1000;
////console.log(userOffsetInMilliseconds);
    // Get the current time in UTC for the user
    const utcDateTime = new Date(localDateTime.getTime() + userOffsetInMilliseconds);
    ////console.log(utcDateTime);
    
    // Format the UTC date into the required string format (without the offset)
    const formattedDate = `${utcDateTime.getFullYear()}${String(utcDateTime.getMonth() + 1).padStart(2, '0')}${String(utcDateTime.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(utcDateTime.getHours()).padStart(2, '0')}${String(utcDateTime.getMinutes()).padStart(2, '0')}00`; 
    
    ////console.log(formattedTime, formattedDate);
    
    return `${formattedDate}T${formattedTime}Z`;
};

// Example usage:



  return (

    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar unsavedChanges={unsavedChanges} />
      </div>
     
      {modalVisible && (
        <Modal
          title={modalTitle}
          message={modalMessage}
          onClose={handleCloseModal}
        />
      )}
      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full p-4 md:p-6 relative">
        <PageNameAndDate
          pageName="Course Detail"
          showBackButton={true}
          backLink="/instructor/dashboard/courses"
          unsavedChanges={unsavedChanges}
        />
        {selectedStartDate && (
          <div className="flex justify-end gap-8 ">
           <p>Student Enrolled: {studentsCount}</p>
            <Link
              className=""
              to={`/instructor/dashboard/course/showStudentsByCourse/${params.id}/${selectedStartDate}`}
            >
              <button className="bg-blue-600  mb-2 py-1 rounded-lg  text-white w-32 text-sm hover:bg-blue-700 ">
                Show Students
              </button>
            </Link>
          </div>
        )}
        {loading && (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      )}
        <div className="px-4 mb-6 border p-4 shadow-md rounded-lg ml-4 md:ml-0">
          <div className="w-full flex flex-col gap-4 md:flex-row md:justify-between">
            {/* Name */}
            <div className="flex flex-col w-full md:w-1/3">
              <div className="flex justify-between">
                <label className="font-semibold">Name</label>
                <EditIcon
                  className="cursor-pointer mb-1"
                  onClick={() => toggleEdit("title")}
                />
              </div>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="border p-2 rounded-lg mb-2"
                placeholder="Title"
                disabled={!isEditable.title}
              />
            </div>

            {/* Price */}
            <div className="flex flex-col w-full md:w-1/3">
              <div className="flex justify-between">
                <label className="font-semibold">Price ($)</label>
                <EditIcon
                  className="cursor-pointer"
                  onClick={() => toggleEdit("price")}
                />
              </div>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="border p-2 rounded-lg mb-2"
                placeholder="Price"
                disabled={!isEditable.price}
              />
            </div>

            {/* Duration */}
            <div className="flex flex-col w-full md:w-1/3">
              <div className="flex justify-between">
                <label className="font-semibold">Duration (weeks)</label>
                <EditIcon
                  className="cursor-pointer"
                  onClick={() => toggleEdit("duration")}
                />
              </div>
              <input
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleInputChange}
                className="border p-2 rounded-lg mb-4"
                placeholder="Duration (weeks)"
                disabled={!isEditable.duration}
              />
            </div>
          </div>

          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-3 text-sm py-2 mt-2 rounded-lg hover:bg-green-700 shadow-md w-full md:w-auto"
          >
            Save Changes
          </button>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h3 className="text-lg font-semibold mb-4 text-center">
                Select Start Date
              </h3>
              <Calendar
                onChange={(date) => setNewStartDate(date)}
                value={selectedStartDate}
                minDate={new Date()}
              />
              <div className="flex flex-col md:flex-row justify-end gap-4 mt-4">
                <button
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg w-full md:w-auto"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveStartDate}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full md:w-auto"
                >
                  Save Date
                </button>
              </div>
            </div>
          </div>
        )}
        <div>
          {" "}
          {formData?.startDates && (
            <div className="ml-4 border shadow-lg p-4 rounded-lg">
              <div className="w-full flex flex-col md:flex-row gap-8 md:gap-8 justify-between">
                <div className="flex flex-col gap-2 w-full md:w-1/2">
                  <h3 className="text-md font-semibold w-full pl-1">
                    Start Dates
                  </h3>

                  <div className="flex flex-col sm:flex-row gap-4">
                    <div className="flex">
                      <select
                        onChange={handleStartDateChange}
                        className="border p-2 rounded-lg mb-4 w-full"
                      >
                        <option value="">Select Start Date</option>
                        {formData.startDates.map((startDate, index) => (
                          <option
                            key={index}
                            value={new Date(startDate.startDate).toISOString()}
                          >
                            {format(
                              new Date(startDate.startDate),
                              "yyyy-MM-dd"
                            )}
                          </option>
                        ))}
                      </select>

                      {selectedStartDate && (
                        <>
                        <button
                          onClick={() =>
                            handleDeleteStartDate(selectedStartDate)
                          }
                          className="text-red-500 hover:text-red-700 self-start mt-2 sm:mt-1 "
                        >
                          <DeleteIcon />
                        </button>
                        {confirmDialogOpen && (
  <ConfirmDialog
    isOpen={confirmDialogOpen}
    title="Confirm Delete"
    message="Are you sure you want to delete this start date?"
    onConfirm={confirmDelete}
    onCancel={cancelDelete}
  />
)}
</>

                      )}
                    </div>

                    <button
                      className="bg-blue-600 text-sm py-2 px-3 self-start mt-1 sm:mt-0 shadow-lg rounded-lg text-white w-full sm:w-auto"
                      onClick={openModal}
                    >
                      Add Start Date
                    </button>
                  </div>
                </div>

                {selectedStartDate && (
                  <div className="flex flex-col gap-2 w-full md:w-1/2">
                    <h3 className="text-md font-semibold w-full pl-1">
                      Add Meeting Link
                    </h3>
                    <div className="flex flex-col sm:flex-row gap-2">
                      <div className="flex flex-col md:w-1/2 w-full">
                        <input
                          type="text"
                          name="meetingLink"
                          className="border p-2 rounded-lg mb-4"
                          placeholder="Add Meeting Link"
                          value={meetingLink}
                          onChange={(e) => handleMeetingLinkChange(e)}
                        />
                        {meetingError && (
                          <p className="text-red-500">{meetingError}</p>
                        )}
                      </div>

                      <button
                        className="bg-blue-600 text-sm py-2 px-3 self-start mt-1 sm:mt-0 shadow-lg rounded-lg text-white w-full sm:w-auto"
                        onClick={handleAddMeetingLink}
                      >
                        Add Meeting Link
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {selectedStartDate && (
          <div className="w-full p-4">
            <h3 className="text-md font-semibold mb-4">Upcoming Class Dates</h3>

            <div className="border flex flex-col md:flex-row justify-around w-full p-4 gap-8 md:gap-12 rounded-lg shadow-lg">
              <div className="w-full md:w-1/2">
                <Calendar
                  onChange={handleClassDateChange}
                  value={selectedClassDate ? new Date(selectedClassDate) : null}
                  minDate={selectedStartDate}
                  maxDate={addWeeks(
                    selectedStartDate,
                    parseInt(formData.duration)
                  )}
                  tileClassName={({ date }) => {
                    return formData.classTimes.some((classTime) =>
                      isSameDay(new Date(classTime.date), date)
                    )
                      ? "bg-green-400 text-green-800 rounded-full shadow-md"
                      : "";
                  }}
                  className="border rounded-lg bg-green-500 w-full"
                />
              </div>

              {selectedClassDate && (
                <div className="mt-4 w-full md:w-1/2">
                  <h4 className="text-lg font-semibold">
                    Class Times for {selectedClassDate}
                  </h4>

                  {formData.classTimes.some(
                    (time) => time.date?.split("T")[0] === selectedClassDate
                  ) ? (
                    formData.classTimes
                      .filter(
                        (time) => time.date.split("T")[0] === selectedClassDate
                      )
                      .map((classTime) => (
                        <div
                          key={classTime._id}
                          className="border p-4 mt-2 rounded-lg shadow-md"
                        >
                          <label className="block">Start Time:</label>
                          <input
                            type="time"
                            value={formatTimeForInput(classTime.startTime)}
                            onChange={(e) => {
                              const updatedTime = updateTimeForSave(
                                selectedClassDate,
                                e.target.value
                              );
                              handleClassTimeChange(
                                classTime._id,
                                "startTime",
                                updatedTime
                              );
                            }}
                            className="border p-2 w-full rounded-lg mb-2"
                          />

                          <label className="block">End Time:</label>
                          <input
                            type="time"
                            value={formatTimeForInput(classTime.endTime)}
                            onChange={(e) => {
                              const updatedTime = updateTimeForSave(
                                selectedClassDate,
                                e.target.value
                              );
                              handleClassTimeChange(
                                classTime._id,
                                "endTime",
                                updatedTime
                              );
                            }}
                            className="border p-2 w-full rounded-lg mb-2"
                          />

                          {timeError && (
                            <p className="text-red-500 text-sm">{timeError}</p>
                          )}

                          <div className="flex flex-col sm:flex-row gap-4">
                            <button
                              onClick={() => removeClassTime(classTime._id)}
                              className="bg-red-500 text-white text-sm px-4 py-2 mt-2 rounded-lg hover:bg-red-700 w-full sm:w-auto"
                            >
                              Remove Time
                            </button>
                            <button
                              onClick={handleSaveClassTimes}
                              className="bg-blue-500 text-white text-sm px-4 py-2 mt-2 rounded-lg hover:bg-blue-700 shadow-md w-full sm:w-auto"
                            >
                              Save Time
                            </button>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="border p-4 mt-2 rounded-lg shadow-md">
                      <label className="block">Start Time:</label>
                      <input
                        type="time"
                        onChange={(e) => {
                          const updatedTime = updateTimeForSave(
                            selectedClassDate,
                            e.target.value
                          );
                          handleClassTimeChange(null, "startTime", updatedTime);
                        }}
                        className="border p-2 w-full rounded-lg mb-2"
                      />

                      <label className="block">End Time:</label>
                      <input
                        type="time"
                        onChange={(e) => {
                          const updatedTime = updateTimeForSave(
                            selectedClassDate,
                            e.target.value
                          );
                          handleClassTimeChange(null, "endTime", updatedTime);
                        }}
                        className="border p-2 w-full rounded-lg mb-2"
                      />

                      {timeError && (
                        <p className="text-red-500 text-sm">{timeError}</p>
                      )}

                      <button
                        onClick={handleSaveClassTimes}
                        className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-700 shadow-md w-full"
                      >
                        Save Class Times
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="fixed bottom-4 right-4"></div>
    </div>
 
  );
};

export default InstructorCourseDetail;
