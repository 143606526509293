import React from 'react';

const StatsChart = ({ solvedQuestion, correctNumber, incorrectNumber, totalQuestions, type }) => {
  const correctPercentage = Math.min((correctNumber / totalQuestions) * 100, 100);
  const incorrectPercentage = Math.min((incorrectNumber / totalQuestions) * 100, 100);
  const solvedPercentage = Math.min((solvedQuestion / totalQuestions) * 100, 100);

  const remainingSolvedPercentage = Math.max(solvedPercentage - correctPercentage - incorrectPercentage, 0);

  return (
    <div className="flex flex-col items-center w-full max-w-sm">
      <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden flex">
        {/* Conditionally render bars based on type */}
        {type === 'test' ? (
          // Only show blue bar in test mode
          <div
            className="h-full bg-blue-500"
            style={{ width: `${solvedPercentage}%` }}
            title={`Solved: ${solvedQuestion}/${totalQuestions}`} // Tooltip showing solved questions on hover
          ></div>
        ) : (
          <>
            {/* Correct (Green) with hover tooltip */}
            <div
              className="h-full bg-green-500"
              style={{ width: `${correctPercentage}%` }}
              title={`Correct: ${correctNumber}`} // Tooltip showing correct number on hover
            ></div>
            {/* Incorrect (Red) with hover tooltip */}
            <div
              className="h-full bg-red-500"
              style={{ width: `${incorrectPercentage}%` }}
              title={`Incorrect: ${incorrectNumber}`} // Tooltip showing incorrect number on hover
            ></div>
            {/* Remaining Solved (Blue) */}
            <div
              className="h-full bg-blue-500"
              style={{ width: `${remainingSolvedPercentage}%` }}
              title={`Remaining Solved: ${solvedQuestion - correctNumber - incorrectNumber}`} // Tooltip showing remaining solved on hover
            ></div>
          </>
        )}
      </div>

      <div className="mt-2 flex w-full justify-around text-sm font-bold text-center">
        <p>Solved: {solvedQuestion}/{totalQuestions}</p>
        {/* Show correct and incorrect stats only in non-test mode */}
        {type !== 'test' && (
          <>
            <p>Correct: {correctNumber}</p>
            <p>Incorrect: {incorrectNumber}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default StatsChart;
