// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    /* Hide everything except the printable area */
    body * {
      visibility: hidden;
    }
  
    #printableArea, #printableArea * {
      visibility: visible;
    }
  
    #printableArea {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/StudentDashboard/Payment/Print.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C;MACE,kBAAkB;IACpB;;IAEA;MACE,mBAAmB;IACrB;;IAEA;MACE,kBAAkB;MAClB,OAAO;MACP,MAAM;MACN,WAAW;IACb;EACF","sourcesContent":["@media print {\r\n    /* Hide everything except the printable area */\r\n    body * {\r\n      visibility: hidden;\r\n    }\r\n  \r\n    #printableArea, #printableArea * {\r\n      visibility: visible;\r\n    }\r\n  \r\n    #printableArea {\r\n      position: absolute;\r\n      left: 0;\r\n      top: 0;\r\n      width: 100%;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
