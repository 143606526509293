import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const SimulatorContext = createContext();

export const SimulatorProvider = ({ children }) => {
    const [simulatorDetails, setSimulatorDetails] = useState({
        completed: false,
        paused: false,
        moduleName: '',
    });

  

    return (
        <SimulatorContext.Provider value={{ simulatorDetails, setSimulatorDetails }}>
            {children}
        </SimulatorContext.Provider>
    );
};
