import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../styles/Navbar";
import Footer from "../../LandingPage/Footer";
import { useCookies } from "react-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../../styles/Modal";
import ConfirmDialog from "./utilities/Dialog";


const CourseDetailPage = () => {
  const { type, courseName } = useParams(); // Extract type and name from URL
  // console.log(type, courseName);
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["PMI-cart"]);
  const [startDate, setStartDate] = useState(""); // State for selected start date
  const [availableDates, setAvailableDates] = useState([]); // State to hold available start dates
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for user authentication status
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(""); // New state for confirmation dialog

  const [modalData, setModalData] = useState({ title: "", message: "" });
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    // Dynamically set image based on courseName
    if (courseName && courseName.toLowerCase().includes("pmp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/PMP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("pgmp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/PgMP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("acp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/ACP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("capm")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/CAPM-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("sp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/SP-img.png`);
    } else {
      setImageSrc(`${process.env.PUBLIC_URL}/images/default-course-img.png`);
    }
  }, [courseName]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.user.userType === "student") {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/courses/getCourseDetails`,
          {
            params: { type, courseName },
          }
        );
        setCourse(response.data);

        // console.log(response.data);
        const formattedDates = response.data.startingDates?.map((date) =>
          new Date(date.startDate).toLocaleDateString(undefined, {
            // Pass 'undefined' for user's local time
            day: "numeric",
            month: "short",
            year: "numeric",
            weekday: "long",
          })
        );

        setAvailableDates(formattedDates);
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching course details:", error);
        setLoading(false);
      }
    };

    fetchCourse();
  }, [type, courseName]);

  useEffect(() => {}, [startDate]);
  
  const handleAddToCart = () => {
    let dateToSend =
      type === "courses" ? startDate : new Date().toLocaleDateString();

    // Check if a start date is required
    if (type === "courses" && !startDate) {
      setModalData({
        title: "Can't add item to Cart",
        message:
          "Please select a start date before adding the course to the cart.",
      });
      setIsModalOpen(true);
      return;
    }

    if (course) {
      if (isAuthenticated) {
        axios
          .get(`${process.env.REACT_APP_SERVER_URL}/student/verifyPurchase`, {
            withCredentials: true,
            params: { courseId: course._id, type: "course" },
          })
          .then((response) => {
            if (response.data.hasPurchased) {
              setDialogMessage(
                "You have already purchased this course. Do you still want to add it to your cart?"
              );
              setShowConfirmationDialog(true); // Show confirmation dialog if already purchased
            } else {
              addToCart(dateToSend); // Directly add if not purchased
            }
          })
          .catch((error) => {
            // console.error(error);
          });
      } else {
        addToCart(dateToSend); // Directly add if not authenticated
      }
    }
  };

  const addToCart = (dateToSend) => {
    const existingCart = cookies["PMI-cart"] ? cookies["PMI-cart"] : [];

    // Check if the course with the same title already exists in the cart
    const courseExists = existingCart.some(
      (item) => item.title === course.title
    );

    if (courseExists) {
      setDialogMessage(
        "This course is already in your cart. Do you want to add it again?"
      );
      setShowConfirmationDialog(true); // Show confirmation dialog
    } else {
      // If the course does not exist, add it directly to the cart
      addToCartConfirmed(dateToSend); // Call the function to add the course directly
    }
  };

  // Confirm adding course again
  const handleConfirmAddToCart = () => {
    const dateToSend =
      type === "courses" ? startDate : new Date().toLocaleDateString();
    addToCartConfirmed(dateToSend); // Directly add the course to cart on confirmation
  };

  // Function to actually add the course to the cart
  const addToCartConfirmed = (dateToSend) => {
    const existingCart = cookies["PMI-cart"] ? cookies["PMI-cart"] : [];

    const updatedCart = [
      ...existingCart,
      {
        startDate: dateToSend,
        price: course.price,
        duration: course.duration,
        title: course.title,
      },
    ];

    // Update the cart in cookies
    setCookie("PMI-cart", JSON.stringify(updatedCart), { path: "/" });

    // Show success message
    setModalData({
      title: "Success",
      message: "The item has been successfully added to your cart!",
    });

    setIsModalOpen(true);

    // Hide the confirmation dialog
    setShowConfirmationDialog(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowConfirmationDialog(false); // Close confirmation dialog
  };

  const renderContentWithInlineStyles = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    const elements = doc.querySelectorAll('.ql-align-center');
    elements.forEach((el) => {
      el.style.textAlign = 'center'; // Apply inline style
      el.classList.remove('ql-align-center'); // Optionally remove the class
    });
  
    // Add class names based on tag name
    const headingsAndParagraphs = doc.querySelectorAll('h1, h2, h3, h4, p');
    headingsAndParagraphs.forEach((el) => {
      switch (el.tagName) {
        case 'H1':
          el.classList.add('text-3xl'); // Style for <h1>
          break;
        case 'H2':
          el.classList.add('text-2xl'); // Style for <h2>
          break;
        case 'H3':
          el.classList.add('text-xl'); // Style for <h3>
          break;
        case 'H4':
          el.classList.add('text-lg'); // Style for <h4>
          break;
        case 'H5':
          el.classList.add('text-base'); // Style for <h5>
          break;
        case 'H6':
          el.classList.add('text-base'); // Style for <h6>
          break;
        case 'P':
          el.classList.add('text-sm'); 
          break;
        default:
          break;
      }
    });
    

return doc.body.innerHTML; // Return the modified HTML
  }
  
  
  return (
    <div>
      <Navbar textColor="text-blue-800" />
      <div className="relative">
        <div
          style={{
            background: "linear-gradient(to right, #131F4B 42%, #2C49B1 97%)",
            width: "100%",
          }}
          className="relative flex flex-col lg:flex-row items-center justify-between px-4 lg:px-8 text-white h-[80%] py-20"
        >
          {/* Text Content */}

          <ConfirmDialog
            isOpen={showConfirmationDialog}
            title="Confirmation"
            message={dialogMessage}
            onConfirm={handleConfirmAddToCart} // Action to confirm addition to cart
            onCancel={handleCloseModal}
          />

          <div className="flex flex-col w-full lg:w-1/2 z-10 text-center lg:text-left">
            <h1 className="text-lg lg:text-xl font-bold mb-2  mt-8 sm:mt-0">
             {course?.title}
            </h1>
            <p className="mb-2 text-gray-200 text-xs lg:text-sm">
              Get your PMI certification now
            </p>
            <p className="my-4 text-sm lg:text-lg">
              {course?.description}
            </p>
            <p className="mb-4">
              <span className="font-bold">Price:</span> ${course?.price}
            </p>
            <div className=" flex w-full flex-wrap gap-8">
              <div className="md:w-1/4 w-4/5 m-auto md:m-0 ">
                <button
                  className="w-full bg-blue-100 text-gray-900 text-md  font-semibold py-2 px-2 mt-6 rounded-lg hover:bg-gray-100 active:bg-blue-200"
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
              </div>
              <div className=" md:text-start md:w-1/3 w-full ">
                <>
                  <select
                    id="startDate"
                    className="w-4/5 bg-white text-gray-900 py-2 px-2 mt-6 rounded-lg"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                    <option value="">-- Select a Date --</option>
                    {availableDates.length > 0 ? (
                      availableDates?.map((date, index) => (
                        <option key={index} value={date}>
                          {date} {/* This assumes date is already formatted */}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No dates available
                      </option>
                    )}
                  </select>
                </>
              </div>
            </div>
          </div>

          {/*  Image */}
          {imageSrc && imageSrc.trim() !== '' && (
  <img
    src={imageSrc}
    alt=""
    rel="preload"
    className="absolute top-0 opacity-10 left-1/2 transform -translate-x-1/2 h-full object-cover z-0 hidden lg:block"
    style={{ zIndex: 5 }}
  />
)}


          {/* Right Image */}
          <div className="w-full lg:w-[40%] h-auto justify-center lg:justify-end z-10 md:mt-4 lg:mt-0 md:flex flex mt-12">
            <img
              src={`${process.env.PUBLIC_URL}/images/courseDetail-main-img.png`}
              alt="Course Detail Main"
              className="w-4/5  lg:w-full"
              rel="preload"
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={modalData.title}
          message={modalData.message}
          onClose={handleCloseModal}
        />
      )}
      <div>
        <h1 className="text-center text-xl lg:text-2xl font-bold my-8 text-blue-800">
          What Included
        </h1>
      </div>

      {/* What Included Cards with Hover Effect */}
      <div className="flex flex-wrap justify-center w-full my-8 gap-4 lg:gap-20">
        <div className="w-2/3 lg:w-1/5 flex flex-col gap-6 border rounded-xl shadow-xl items-center p-4 transform transition-transform duration-300 hover:scale-105 cursor-pointer">
          <img
            src={`${process.env.PUBLIC_URL}/images/Online-icon.png`}
            alt="online"
            className="w-1/5 h-auto m-auto"
          />
          <h2 className="text-sm lg:text-md text-center w-1/2">
            1-1 Training Sessions
          </h2>
        </div>
        {course?.hoursOfTraining && (
          <div className="w-2/3 lg:w-1/5 flex flex-col gap-6 border rounded-xl shadow-xl items-center p-4 transform transition-transform duration-300 hover:scale-105 cursor-pointer">
            <img
              src={`${process.env.PUBLIC_URL}/images/working-hour-icon.png`}
              alt="working-hour"
              className="w-1/5 h-auto m-auto"
            />
            <h2 className="text-sm lg:text-md text-center w-1/2">
              {course.hoursOfTraining} hour Training
            </h2>
          </div>
        )}
        {course?.simulatorId && (
          <div className="w-2/3 lg:w-1/5 flex flex-col gap-6 border rounded-xl shadow-xl items-center p-4 transform transition-transform duration-300 hover:scale-105 cursor-pointer">
            <img
              src={`${process.env.PUBLIC_URL}/images/Certificate-icon.png`}
              alt="certificate"
              className="w-1/5 h-auto m-auto"
            />
            <h2 className="text-sm lg:text-md text-center w-1/2">
              Free Simulator
            </h2>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-8  my-12 w-11/12 lg:w-4/5 m-auto text-sm lg:text-base">
 

  <div className="">
  {course?.Features && (
  <div className='ql-editor'>
    <div
      className=""
      dangerouslySetInnerHTML={{ __html: renderContentWithInlineStyles(course.Features) }}
    />
  </div>
)}

</div>


 
</div>

      {loading && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}
      <Footer />
    </div>
  );
};

export default CourseDetailPage;
