import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useLocation } from "react-router-dom";
import Modal from "../../../styles/Modal";

const SimulatorModuleDetail = () => {
  const { id } = useParams();
  const [simulator, setSimulator] = useState({
    module: {
      questions: [],
      time: ""
    }
  });
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [modalTitle, setModalTitle] = useState(""); // State for modal title
  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    options: ["", "", "", ""],
    correctOption: "",
    explanation: "",
  });
  const [editQuestion, setEditQuestion] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [jsonUpload, setJsonUpload] = useState("");
  const [jsonError, setJsonError] = useState(null);  // Error state for JSON
  const [editTime, setEditTime] = useState(false);
  const [moduleTime, setModuleTime] = useState("");
  const location = useLocation();
  const moduleTitle = location.state?.title;

  // Fetch simulator details
  const fetchSimulatorDetails = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/getSimulatorDetail/${id}/${moduleTitle}`, {
        withCredentials: true,
      })
      .then((response) => {
        //console.log(response.data);
        setSimulator(response.data);
        setModuleTime(response.data.module.maxTime);
      })
      .catch((error) => {
        //console.error("Error fetching simulator details:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Check for authentication
 

  // Fetch simulator details on authentication change
  useEffect(() => {
      fetchSimulatorDetails();
    
  }, [id, moduleTitle]);

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...newQuestion.options];
    updatedOptions[index] = value;
    setNewQuestion((prev) => ({ ...prev, options: updatedOptions }));
  };

  const handleSaveQuestion = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/addQuestion/${id}/${moduleTitle}`, newQuestion, {
        withCredentials: true,
      })
      .then((response) => {
        //console.log("Question added successfully:", response.data);
        setSimulator((prev) => ({
          ...prev,
          questions: [...prev.questions, response.data],
        }));
        setNewQuestion({
          questionText: "",
          options: ["", "", "", ""],
          correctOption: "",
          explanation: "",
        });
      })
      .catch((error) => {
        //console.error("Error adding question:", error);
      });
  };

  const handleUpdateQuestion = (questionId, updatedQuestion) => {
    // Validation: Check if question text is empty
    if (!updatedQuestion.questionText.trim()) {
      setModalTitle("Error");
      setModalMessage("Question text cannot be empty.");
      setModalVisible(true); 
      return;
    }
  
    // Validation: Check if any option is empty
    if (updatedQuestion.options.some(option => option.trim() === "")) {
      setModalTitle("Error");
      setModalMessage("All options must be filled.");
      setModalVisible(true); 
      return;
    }
  
    // Validation: Check if the correct option is empty
    if (!updatedQuestion.correctOption.trim()) {
      setModalTitle("Error");
      setModalMessage("Correct option cannot be empty.");
      setModalVisible(true); 
      return;
    }
  
    // Validation: Check if the correct option matches one of the available options
    if (!updatedQuestion.options.includes(updatedQuestion.correctOption)) {
      setModalTitle("Error");
      setModalMessage("Correct option must be one of the available options.");
      setModalVisible(true); 
      return;
    }
  
    // Validation: Check if the explanation is empty
    if (!updatedQuestion.explanation.trim()) {
      setModalTitle("Error");
      setModalMessage("Explanation cannot be empty.");
      setModalVisible(true); 
      return;
    }
    
    // If validation passes, proceed with the API request
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/updateQuestion/${id}/${moduleTitle}/${questionId}`,
        updatedQuestion,
        { withCredentials: true }
      )
      .then((response) => {
        setLoading(false);
        fetchSimulatorDetails();
        //console.log("Question updated successfully:", response.data);
        setEditMode(false);
        setEditQuestion(null);
      })
      .catch((error) => {
        setLoading(false); // Reset loading state in case of an error
        //console.error("Error updating question:", error);
        alert("Error updating question. Please try again.");
      });
  };
  

  const handleEditClick = (question) => {
    setEditQuestion(question);
    setNewQuestion(question);
    setEditMode(true);
  };

  const handleJsonUpload = () => {
    try {
      setJsonError(null);  // Reset error
      const questions = JSON.parse(jsonUpload);
      if (Array.isArray(questions)) {
        setLoading(true);
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/addQuestions/${id}/${moduleTitle}`, { questions: questions }, {
            withCredentials: true,
          })
          .then(() => {
            setLoading(false)
            fetchSimulatorDetails();
            setJsonUpload("");
          })
          .catch((error) => {
            setLoading(false)
            //console.error("Error adding questions:", error);
          });
      } else {
        setJsonError("Invalid JSON format");
        //console.error("Invalid JSON format");
      }
    } catch (error) {
      setJsonError("Error parsing JSON. Please check the format.");
      //console.error("Error parsing JSON:", error);
    }
  };

  const handleDeleteQuestion = (questionId) => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/deleteQuestion/${id}/${moduleTitle}/${questionId}`, {
        withCredentials: true,
      })
      .then((response) => {
        //console.log("Question deleted successfully:", response.data);
        fetchSimulatorDetails();
      })
      .catch((error) => {
        //console.error("Error deleting question:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveModuleTime = () => {
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/updateModuleTime/${id}/${moduleTitle}`, { time: moduleTime }, {
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);
        //console.log("Module time updated successfully:", response.data);
        setEditTime(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error updating module time:", error);
      });
  };

  const handleChange = (e) => {
    setEditTime(true);
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setModuleTime(value);
    }
  };
  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal
  };
  return (
 
    <div className="flex flex-col md:flex-row h-screen ">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full text-sm relative">
        <div className="w-full p-6 mt-4">
          <PageNameAndDate
            pageName={simulator?.module.title}
            showBackButton={true}
            backLink={`/instructor/dashboard/simulator/detail/${id}`}
          />
          {loading && (
            <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )}
          {modalVisible && (
        <Modal
          title={modalTitle}
          message={modalMessage}
          onClose={handleCloseModal}
        />
      )}
          <div className="mt-4">
          
          {(moduleTime || editTime) && <div className="bg-white shadow-md rounded-lg p-4 mb-4">
              <h3 className="text-md font-semibold">Module Time in minutes</h3>
              {/* {editTime ? ( */}
               <div className="flex items-center">
                <input
      type="text" // Use text to fully control input validation
      value={moduleTime}
      onChange={handleChange}
      className="border rounded-lg p-2 w-1/2"
      placeholder="Enter time"
    />
                  <button
                    onClick={handleSaveModuleTime}
                    className="ml-2 bg-green-500 text-white rounded-lg p-2"
                  >
                    Save Time
                  </button>
                  {/* <button
                    onClick={() => setEditTime(false)}
                    className="ml-2 bg-red-500 text-white rounded-lg p-2"
                  >
                    Cancel
                  </button> */}
                </div>
              {/* ) : (
                <div className="flex items-center">
                  <p>{simulator.module.time}</p>
                  <button
                    onClick={() => setEditTime(true)}
                    className="ml-2 bg-blue-500 text-white rounded-lg p-2"
                  >
                    Edit Time
                  </button>
                </div> */}
              {/* )} */}
            </div>}

            {/* Questions */}
            {simulator?.module?.questions?.length > 0 ? (
              simulator.module.questions.map((question) => (
                <div key={question._id} className="bg-white shadow-md rounded-lg p-4 mb-4">
                  <h3 className="text-md font-semibold">{question.questionText}</h3>
                  <ul className="mt-2">
                    {question.options.map((option, index) => (
                      <li key={index}>{index + 1}. {option}</li>
                    ))}
                  </ul>
                  <p className="mt-2">{question.explanation}</p>
                  <button onClick={() => handleEditClick(question)} className="mt-2 bg-blue-500 text-white rounded-lg p-2">Edit</button>
                  <button onClick={() => handleDeleteQuestion(question._id)} className="ml-2 bg-red-500 text-white rounded-lg p-2">
                    <DeleteIcon />
                  </button>
                  {editQuestion?._id === question._id && editMode && (
                    <div className="mt-4">
                      <h4 className="font-semibold">Edit Question</h4>
                      <input
                        type="text"
                        name="questionText"
                        value={newQuestion.questionText}
                        onChange={handleQuestionChange}
                        placeholder="Edit question text"
                        className="border rounded-lg p-2 w-full"
                      />
                      {newQuestion.options.map((option, index) => (
                        <input
                          key={index}
                          type="text"
                          value={option}
                          onChange={(e) => handleOptionChange(index, e.target.value)}
                          placeholder={`Edit option ${index + 1}`}
                          className="border rounded-lg p-2 w-full mt-2"
                        />
                      ))}
                      <p className="mt-2 font-semibold">Correct Option:</p>
                      <input
                        type="text"
                        name="correctOption"
                        value={newQuestion.correctOption}
                        onChange={handleQuestionChange}
                        placeholder="Edit correct option"
                        className="border rounded-lg p-2 w-full mt-2"
                      />
                      <p className="mt-2 font-semibold">Explanation:</p>
                      <textarea
                        name="explanation"
                        value={newQuestion.explanation}
                        onChange={handleQuestionChange}
                        placeholder="Edit explanation"
                        className="border rounded-lg p-2 w-full mt-2"
                      />
                      <button onClick={() => handleUpdateQuestion(question._id, newQuestion)} className="mt-4 bg-green-500 text-white rounded-lg p-2">Save Changes</button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No questions added yet.</p>
            )}

        
            {/* JSON Upload Form */}
            <div className="bg-white shadow-md rounded-lg p-4 mt-4">
              <h3 className="text-md font-semibold">Upload Questions via JSON</h3>
              <textarea
                value={jsonUpload}
                onChange={(e) => setJsonUpload(e.target.value)}
                placeholder='[{"questionText": "What is the capital of France?", "options": ["Paris", "London", "Berlin", "Madrid"], "correctOption": "Paris", "explanation": "Paris is the capital city of France."}, {"questionText": "Which planet is known as the Red Planet?", "options": ["Earth", "Mars", "Jupiter", "Saturn"], "correctOption": "Mars", "explanation": "Mars is often referred to as the Red Planet due to its reddish appearance."}]'
                className="border rounded-lg p-2 h-24 w-full"
              />
              <button onClick={handleJsonUpload} className="mt-4 bg-green-500 text-white rounded-lg p-2">Upload JSON</button>
              {jsonError &&<> <p className="text-red-500 mt-2">{jsonError}</p>
              <p>
  {JSON.stringify([
    {
      questionText: "What is the capital of France?",
      options: ["Paris", "London", "Berlin", "Madrid"],
      correctOption: "Paris",
      explanation: "Paris is the capital city of France."
    },
    {
      questionText: "Which planet is known as the Red Planet?",
      options: ["Earth", "Mars", "Jupiter", "Saturn"],
      correctOption: "Mars",
      explanation: "Mars is often referred to as the Red Planet due to its reddish appearance."
    }
  ], null, 2)}
</p>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
 
  );
};

export default SimulatorModuleDetail;
