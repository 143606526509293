import React, { useEffect, useState,useContext,useRef } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../../utilities/Sidebar";
import PageNameAndDate from "../../../utilities/PageNameAndDate";
import axios from "axios";
import ApexCharts from "react-apexcharts";
import Modal from "../../../../styles/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";

// import { shuffle } from "lodash";
import StatsChart from "./StatsCharts";
import { useCookies } from "react-cookie";
// import { SimulatorContext } from "../../../../../context/AuthContext";


const SimulatorModule = () => {

  const [moduleDetails, setModuleDetails] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timerActive, setTimerActive] = useState(false);
  const [view, setView] = useState("questions"); // View mode: 'questions' or 'results'
  const { simulatorTitle, moduleTitle } = useParams(); // Extract simulatorTitle and moduleId from URL
  const [showModal, setShowModal] = useState(false);
  let [solvedQuestion, setSolvedQuestions] = useState(0);
  let [correctNumber, setCorrectNumber] = useState(0);
  let [inCorrectNumber, setInCorrectNumber] = useState(0);
  const [isTestCompleted, setIsTestCompleted] = useState(false); // Add state for test completion
  const [moduleType, setModuleType] = useState("");
  const [unsolvedQuestions, setUnsolvedQuestions] = useState(false);
  // const [setPauseTest] = useState(false);
  const [cookies, setCookie] = useCookies(["simulatorInfo"]);
  // const { simulatorDetails, setSimulatorDetails } = useContext(SimulatorContext);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [questionsLimit, setNumQuestions] = useState('');
  const [questionsLimitError, setNumQuestionsError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (moduleDetails?.questions) {
      // Step 1: Separate solved and unsolved questions
      const solvedQuestions = moduleDetails?.questions.filter(question => answeredQuestions[question.questionId]);
      const unsolvedQuestions = moduleDetails?.questions.filter(question => !answeredQuestions[question.questionId]);
  
      // Step 2: Shuffle unsolved questions if there are any unsolved questions
      const shuffledUnsolvedQuestions = solvedQuestions?.length > 0 
        ? unsolvedQuestions  // Don't shuffle if solved questions exist
        : shuffleArray(unsolvedQuestions);  // Shuffle if no questions are solved
  
      // Step 3: Combine solved and shuffled unsolved questions
      const sortedAndShuffledQuestions = [...solvedQuestions, ...shuffledUnsolvedQuestions];
  
      // Step 4: Set the shuffled questions
      setShuffledQuestions(sortedAndShuffledQuestions);
    }
  }, [moduleDetails?.questions, isTestCompleted]);



  // useEffect(() => {
  //   if (solvedQuestion == moduleDetails?.questionsLimit) {
  //     setIsTestCompleted(true); // Mark test as completed
  //     if (timerActive) {
  //       setTimerActive(false);
  //       setShowModal(true);
  //     }
  //   }
  // }, [solvedQuestion, moduleDetails?.questions?.length]);

  const fetchData=async()=>{
    if (simulatorTitle && moduleTitle) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulators/${simulatorTitle}/${moduleTitle}`,
          { withCredentials: true }
        )
        .then((response) => {
          setLoading(false);
          const data = response.data;
          //console.log(data)
                    setModuleDetails(data); 
          setModuleType(data?.moduleType);
          if (cookies.simulatorInfo && cookies.simulatorInfo.simulatorName === data.moduleName) {
            setIsTestCompleted(cookies.simulatorInfo.isCompleted);
          }

          // Initialize answered questions and selected answers with previously solved data
          const initialAnsweredQuestions = {};
          const initialSelectedAnswers = {};
          let initialTime = "";
          const totalQuestions = data.questions.length;
          const limit = data?.questionsLimit || totalQuestions;
          
          // Check if questionsLimit exceeds the total number of questions
          if (limit > totalQuestions) {
              // If questionsLimit exceeds the total questions, set timer according to total questions
              initialTime = totalQuestions * 84;
          } else {
              // Otherwise, set the timer according to questionsLimit
              initialTime = limit * 84;
          }
          
        //   if (data?.questionsLimit) {
        //     // Calculate timer based on questionsLimit (84 seconds per question)
        //     initialTime = data.questionsLimit * 84;
        // } 
        // } else {
        //     // Default to maxTime (in minutes) converted to seconds
        //     initialTime = data.maxTime * 60;
        // }
        

          let solvedCount = 0;
          let correctCount = 0;
          let incorrectCount = 0;

          data.questions.forEach((question) => {
            if (question.isSolved) {
              initialAnsweredQuestions[question.questionId] = true;
              initialSelectedAnswers[question.questionId] =
                question.answeredOption;

              // Update solved count
              solvedCount += 1;

              // Check if the answer is correct
              if (question.correctOption === question.answeredOption) {
                correctCount += 1;
              } else {
                incorrectCount += 1;
              }
            }
          });

          setAnsweredQuestions(initialAnsweredQuestions);
          setSelectedAnswers(initialSelectedAnswers);
          setSolvedQuestions(solvedCount);
          setCorrectNumber(correctCount);
          setInCorrectNumber(incorrectCount);
          setTimer(initialTime); // Start the timer with the total time already spent
        })
        .catch((error) => {
          setLoading(false);
          //console.error("Error fetching module details:", error);
        });
    }
  }
  useEffect(() => {
   fetchData();
  }, [simulatorTitle, moduleTitle]);

  const handleResetQuestions = (timerState) => {

    if (!timerState) {
      if (timerActive) {
        return;
      }
    }
    if(moduleDetails.moduleType==='test'){
      setCookie("simulatorInfo", JSON.stringify(''), { path: "/" });
    }


    setLoading(true); // Show loader while resetting
    // setPauseTest(true);
    //   setSimulatorDetails(prevState => ({
    //     ...prevState,
    //     paused: false, 
    // }));
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulator/resetQuestions`,
        {
          simulatorTitle,
          moduleTitle,
          maxTime: moduleDetails?.maxTime,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setSolvedQuestions(0);
        setAnsweredQuestions({});
        setSelectedAnswers({});
        setCorrectNumber(0);
        setInCorrectNumber(0);
        setIsTestCompleted(false);
        setTimer(moduleDetails.questionsLimit? moduleDetails.questionsLimit * 84:0);
        setTimeout(()=>{
          setLoading(false);
        },1300)
        //console.log("Questions reset successfully:", response.data);
        // window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        if(error.response.data.message==='No solved questions to reset'){
          
          setTimer(moduleDetails.questionsLimit? moduleDetails.questionsLimit * 84:0);
          setTimerActive(false)
          setTimeout(()=>{
            setLoading(false);
          },1300)
        }
        //console.error("Error resetting questions:", error);
      });
  };

  useEffect(() => {
    if (moduleDetails?.moduleType === "test") {
      let interval;
      if (timerActive && timer > 0) {
        if (solvedQuestion === moduleDetails?.questions.length) {
          setUnsolvedQuestions(false);
        } else {
          setUnsolvedQuestions(true);
        }
        interval = setInterval(() => {
          setTimer((prev) => {
            if (prev - 1 <= 0) {
              setTimerActive(false);
              clearInterval(interval);
              setUnsolvedQuestions(false);
              setIsTestCompleted(true)
              setShowModal(true); // Show modal when timer ends
              // Stop the timer
              return 0; // Ensure timer is set to 0
            } else {
              return prev - 1;
            }
          });
        }, 1000);
      } else if (!timerActive && timer !== 0) {
        clearInterval(interval);
        setUnsolvedQuestions(false);
      }
      return () => clearInterval(interval);
    }
  }, [timerActive, timer]);

  const handleCloseModal = () => {
    // setSolvedQuestions(0);
    setCorrectNumber(0);
    setInCorrectNumber(0);
    setShowModal(false);
    // setView('results')
  };

  const handleOptionClick = async(questionId, option) => {
    const isTestMode = moduleDetails.moduleType === "test";

    // Prevent re-answering in non-test mode if the question is already answered
    if (!isTestMode && answeredQuestions[questionId]) {
      return;
    }

    // Check if the question has been previously solved, only increment if not
    if (!answeredQuestions[questionId]) {
      setSolvedQuestions((prevSolved) => prevSolved + 1);
    }

    // Update the selected answer
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: option,
    }));

    // Update answered status
    setAnsweredQuestions((prev) => ({
      ...prev,
      [questionId]: true,
    }));

    // Determine if the selected option is correct
    const correctOption = moduleDetails.questions.find(
      (q) => q.questionId === questionId
    ).correctOption;
    const isCorrect = option === correctOption;

    // Update correct and incorrect numbers based on the answer
    if (isTestMode) {
      // In test mode, always update the count
      setCorrectNumber((prev) => (isCorrect ? prev + 1 : prev));
      setInCorrectNumber((prev) => (isCorrect ? prev : prev + 1));
    } else {
      // In non-test mode, only update counts if the answer is correct
      if (isCorrect) {
        setCorrectNumber((prev) => prev + 1);
      } else {
        setInCorrectNumber((prev) => prev + 1);
      }
    }

    // Send the updated answer to the server
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulator/updateQuestion`,
        {
          simulatorTitle,
          moduleTitle,
          questionId,
          answeredOption: option,
          isCorrect,
          timer,
        },
        { withCredentials: true }
      );
      // Success handling
      //console.log("Question updated successfully:", response.data);
    } catch (error) {
      // Error handling
      if (error.response) {
        // Server responded with a status code other than 2xx
        //console.error("Error response data:", error.response.data);
      } 
      else {
        // Something happened in setting up the request
        //console.error("Error message:", error.message);
      }
      //console.error("Error config:", error.config);
    }
  }

 
  const handleStartTimer = () => {
    if (cookies?.simulatorInfo?.isCompleted) {
      return;
    }
    if (solvedQuestion === moduleDetails.questions.length) {
      // return;
      setTimerActive(true);
    }

    if (moduleType === 'test' && solvedQuestion == moduleDetails?.questionsLimit) {
      // return; // Test is complete based on questionsLimit
      setTimerActive(true);
    } else if (solvedQuestion == moduleDetails.questions.length) {
      // return; // All questions have been solved
      setTimerActive(true);
    }
    if(solvedQuestion>0){
      setTimerActive(true)
    }
    else{
      setDialogOpen(true);
    }
    
  };

  // Function to handle closing the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setNumQuestions('');
    setNumQuestionsError('');
  };

  // Function to save the number of questions entered by the user
  const handleSaveNumQuestions = () => {
    const parsedNumQuestions = parseInt(questionsLimit, 10);

    // Validate that the number of questions is a positive integer
    if (isNaN(parsedNumQuestions) || parsedNumQuestions <= 0) {
      setNumQuestionsError('Please enter a valid number of questions.');
      return;
    }

    // Save the number of questions to the user's model via an API call
    setDialogOpen(false);
    setLoading(true)
    const { questions, ...moduleDetailsWithoutQuestions } = moduleDetails;
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulator/saveQuestionsLimit`, {
        userId: cookies.userId, // Assuming you have the user ID in cookies
        simulatorTitle,
        moduleTitle,
        moduleId:moduleDetails.id,
        moduleDetails: moduleDetailsWithoutQuestions, // Sending moduleDetails without questions field
        questionsLimit: parsedNumQuestions,
      }, {
        withCredentials: true,
      })
      .then((response) => {
        fetchData();
        //console.log('Number of questions saved successfully:', response.data);
        
        // Close the dialog and activate the timer
        // setDialogOpen(false);
        setLoading(false)
        setTimerActive(true);
      })
      .catch((error) => {
        setLoading(false)
        //console.error('Error saving number of questions:', error);
      });
  };


  const handleStopTimer = async (action) => {
    // //console.log(simulatorDetails.paused)

    if (!timerActive && action!=='finishTest') {
      return;
    }
    if(action == "finishTest"){
      
      setCookie("simulatorInfo", JSON.stringify({simulatorName:moduleDetails?.moduleName,isCompleted:true}), { path: "/" });
      // setPauseTest(false);
      setIsTestCompleted(true);
      setShowModal(true);
      
    }
    else{
      // setPauseTest(true);
    //   setSimulatorDetails(prevState => ({
    //     ...prevState,
    //     paused: !prevState.paused, 
    // }));
  

    }

    setTimerActive(false);

if(solvedQuestion>0){
  try {
    setLoading(true);
    await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulator/updateModuleTimer`,
      {
        simulatorTitle,
        moduleTitle,
        timer,
      },
      { withCredentials: true }
    );

    // // Show the modal after the request is complete
    // if (
    //   solvedQuestion == (moduleType === 'test' ? moduleDetails?.questionsLimit : moduleDetails.questions.length)
    // ) {
    //   setShowModal(true);
    // }
    // setShowModal(true);
  } catch (error) {
    //console.error("Error updating timer:", error);
    // Optionally handle the error (e.g., show an error message to the user)
  } finally {
    // Ensure timerActive is set to false regardless of success or failure
    setLoading(false);
    setTimerActive(false);
  }
}
    
  };

  const handleShowResults = () => {
    if (moduleDetails.moduleType === "test") {
      // In test mode, results only shown after timer or all questions are solved
      // if (solvedQuestion === moduleDetails.questions.length || timer <= 0) {
      //   setView("results");
      // }
      if (!timerActive && (isTestCompleted || isTestCompleted || solvedQuestion===0) ) {
        setView("results");
      }
    } else {
      setView("results");
    }
  };
  const handleBackToMCQs = () => {
    setView("questions");
  };

  const getChartData = () => {
    const totalQuestions = moduleType === 'test' 
    ? (moduleDetails?.questionsLimit <= moduleDetails?.questions.length 
        ? moduleDetails?.questionsLimit 
        : moduleDetails?.questions.length || 0)
    : moduleDetails?.questions.length || 0;
    const solvedQuestions = moduleDetails?.questions?.filter(
      (q) => answeredQuestions[q.questionId]
    ).length;
    const correctAnswers = Object.entries(selectedAnswers).filter(
      ([qId, ans]) => {
        const question = moduleDetails.questions.find(
          (q) => q.questionId === qId
        );
        return question && ans === question.correctOption;
      }
    ).length;
    const wrongAnswers = solvedQuestions - correctAnswers;

    return {
      pieChartOptions: {
        chart: {
          type: "pie",
        },
        labels: ["Unsolved", "Correct", "Wrong"],
        colors: ["#818589", "#00E396", "#FF4560"],
      },
      pieChartSeries: [
        totalQuestions - solvedQuestions,
        correctAnswers,
        wrongAnswers,
      ],
      barChartOptions: {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: ["Total", "Solved", "Correct", "Incorrect"],
        },
      },
      barChartSeries: [
        {
          name: "Questions",
          data: [totalQuestions, solvedQuestions, correctAnswers, wrongAnswers],
        },
      ],
    };
  };

  const { pieChartOptions, pieChartSeries, barChartOptions, barChartSeries } =
    getChartData();

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
    return(
 
      <div className="flex flex-col md:flex-row h-screen">
        <div className="md:fixed flex flex-col md:flex-row md:h-screen">
          <Sidebar
            unsolvedQuestions={unsolvedQuestions}
            resetQuestions={handleResetQuestions}
          />
        </div>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Enter Number of Questions</DialogTitle>
        <DialogContent>
  <TextField
    label="Number of Questions"
    value={questionsLimit}
    onChange={(e) => {
      const value = e.target.value;

      // Validate if the entered number exceeds available questions
      if (value > moduleDetails.questions.length) {
        setNumQuestionsError(`Cannot exceed ${moduleDetails.questions.length} questions`);
      } else {
        setNumQuestionsError(''); // Clear the error if the input is valid
        setNumQuestions(value); // Set the valid number of questions
      }
    }}
    error={!!questionsLimitError}
    helperText={questionsLimitError}
    fullWidth
    margin="normal"
    type="number"
  />
</DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveNumQuestions} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
        {loading && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}
    
        <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full">
          <div className="w-full md:p-6 p-4 md:mt-4">
            <PageNameAndDate pageName={moduleDetails?.moduleName}
        
             />
            
            {showModal && (
              <Modal
                message={`You have solved ${solvedQuestion} questions from which ${correctNumber} are correct and ${inCorrectNumber} are incorrect`}
                onClose={handleCloseModal}
                title={"Result"}
              />
            )}
    
            {view === "questions" ? (
              <div>
                <div className="flex flex-col lg:flex-row justify-between items-center mb-4 gap-4">
                  <div className="flex justify-between md:w-[80%]  gap-4  ">
                    <div className="w-full md:w-full flex flex-wrap md:gap-0 justify-center md:justify-start gap-4 flex-row items-center lg:items-start">
                      <button
                        onClick={handleResetQuestions}
                        className="bg-green-500 hover:bg-green-300 active:bg-gray-300 active:text-green-800 text-white px-4 py-2 text-xs rounded-4xl shadow-lg hover:scale-105 transition-transform ease-in-out duration-200 mr-4"
                      >
                        Reset Questions
                      </button>
      
                      {moduleType === "test" && (
                        <>
                          <button
                            onClick={solvedQuestion > 0 && !isTestCompleted ? () => handleStopTimer("finishTest") : handleStartTimer}
                            className="bg-blue-600 text-white px-4 py-2 text-xs rounded-4xl shadow-lg mr-2 hover:bg-blue-400 hover:scale-105 active:bg-gray-300 active:text-blue-800 transition-transform ease-in-out duration-200"
                          >
                            {solvedQuestion === 0 ? "Start Test" : isTestCompleted ? "Start Test" : "Finish Test"}
                          </button>
                          
                          <button
                            onClick={timerActive ? handleStopTimer : handleStartTimer}
                            className="bg-red-500 hover:bg-red-300 active:bg-gray-300 active:text-red-800 text-white px-4 py-2 text-xs rounded-4xl shadow-lg hover:scale-105 transition-transform ease-in-out duration-200"
                            disabled={(solvedQuestion === 0 && !timerActive) || isTestCompleted}
                          >
                            {timerActive ? "Pause Test" : "Resume Test"}
                          </button>
                        </>
                      )}
    
                      <button
                        onClick={handleShowResults}
                        className="bg-green-500 hover:bg-green-300 active:bg-gray-300 active:text-green-800 text-white px-4 py-2 text-xs rounded-4xl shadow-lg hover:scale-105 transition-transform ease-in-out duration-200 ml-4"
                      >
                        Show Results
                      </button>
                    </div>
                  </div>
                  
                  <div className="w-full m-auto md:w-1/2">
                    <StatsChart
                      type={moduleType}
                      solvedQuestion={solvedQuestion}
                      totalQuestions={moduleType === 'test' 
    ? (moduleDetails?.questionsLimit <= moduleDetails?.questions.length 
        ? moduleDetails?.questionsLimit 
        : moduleDetails?.questions.length || 0)
    : moduleDetails?.questions.length || 0
}
                      correctNumber={correctNumber}
                      incorrectNumber={inCorrectNumber}
                    />
                  </div>
                </div>
    
                <div className="flex flex-col w-full p-6 sm:h-[70vh] md:h-[70vh] xl:h-[80vh] overflow-auto">

                {((moduleType === "test" && (timerActive || solvedQuestion === parseInt(moduleDetails?.questionsLimit, 10) || solvedQuestion > 0)) || moduleType === "training") && (
  (() => {
    // Step 1: Determine the number of questions to display
    const numberOfQuestionsToShow =
      moduleType === "test"
        ? parseInt(moduleDetails?.questionsLimit, 10) || shuffledQuestions.length // Limit by questionsLimit for "test"
        : shuffledQuestions.length; // Show all questions for "training"

    // Step 2: Render the questions
    return shuffledQuestions
      .slice(0, numberOfQuestionsToShow) // Limit questions based on above logic
      .map((question, questionIdx) => {
        const isAnswered = answeredQuestions[question.questionId];
        const selectedAnswer = selectedAnswers[question.questionId];
        const isCorrect = selectedAnswer === question.correctOption;
        const optionsWithLabels = question.options.map((option, idx) => ({
          label: String.fromCharCode(65 + idx), // Converts 0 to A, 1 to B, etc.
          option,
        }));

        return (
          <div
            key={question.questionId}
            className={`p-4 border rounded-md mb-4 w-full ${
              moduleType === "test" && !timerActive && solvedQuestion > 0 && !isTestCompleted ? "blur-sm" : ""
            }`}
          >
            <h3 className="text-lg font-semibold">
              {questionIdx + 1}. {question.questionText}
            </h3>
            <div className="my-2">
              {optionsWithLabels.map(({ label, option }, idx) => (
                <button
                  key={idx}
                  onClick={() => handleOptionClick(question.questionId, option)}
                  className={`block p-2 mt-1 rounded ${
                    selectedAnswer === option
                      ? moduleType === "test"
                        ? "bg-blue-200"
                        : isCorrect
                        ? "bg-green-200"
                        : "bg-red-200"
                      : "bg-gray-100"
                  } hover:bg-gray-200`}
                  disabled={moduleDetails.moduleType !== "test" && isAnswered}
                >
                  {label}. {option}
                </button>
              ))}
            </div>

            {(moduleType === "test" && isTestCompleted && isAnswered) ||
            (moduleType !== "test" && isAnswered) ? (
              <div className="mt-2 p-2 border rounded-md">
                <p
                  className={`font-semibold ${
                    moduleType === "test"
                      ? isCorrect
                        ? "text-green-600"
                        : "text-red-600"
                      : isCorrect
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {isCorrect ? "Correct!" : "Incorrect!"}
                </p>

                <p className="mt-1">
                  <strong>Explanation:</strong> {question.explanation}
                </p>
              </div>
            ) : null}
          </div>
        );
      });
  })()
)}

                </div>
              </div>
            ) : (
              <>
                <button
                  onClick={handleBackToMCQs}
                  className="bg-blue-500 hover:bg-blue-300 active:bg-gray-300 active:text-blue-800 text-white px-4 py-2 text-sm rounded-4xl shadow-lg hover:scale-105 transition-transform ease-in-out duration-200 ml-4"
                >
                  Back to MCQs
                </button>
    
                <div className="flex flex-col lg:flex-row justify-between">
                  {/* Bar Chart */}
                  <div className="w-full lg:w-1/2">
                    <h3 className="text-lg font-bold">Questions Overview</h3>
                    <ApexCharts
                      options={barChartOptions}
                      series={barChartSeries}
                      type="bar"
                      height={300}
                    />
                  </div>
    
                  {/* Additional Charts */}
                  <div className="w-full lg:w-1/2 mt-4 lg:mt-0">
                    <h3 className="text-lg font-bold">Performance Overview</h3>
                    <ApexCharts
                      options={pieChartOptions}
                      series={pieChartSeries}
                      type="pie"
                      height={300}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
    
        {moduleDetails?.moduleType === "test" && (
          <div className="fixed right-0 top-0 p-4">
            <div className={`text-xl font-bold ${timerActive ? "text-red-500" : "text-black"}`}>
              Timer: {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}
            </div>
          </div>
        )}
      </div>
    
    );
    
};

export default SimulatorModule;