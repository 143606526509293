import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../styles/Navbar";
import Footer from "../../LandingPage/Footer";
import Modal from "../../styles/Modal";
import { useCookies } from "react-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmDialog from "./utilities/Dialog";

const SimulatorDetailPage = () => {
  const { type, courseName } = useParams(); // Extract type and name from URL
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["PMI-cart"]);
  const [startDate, setStartDate] = useState(""); // State for selected start date
  const [setAvailableDates] = useState([]); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for user authentication status
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const[dialogMessage,setDialogMessage]=useState(''); // New state for confirmation dialog
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    // Dynamically set image based on courseName
    if (courseName && courseName.toLowerCase().includes("pmp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/PMP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("pgmp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/PgMP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("acp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/ACP-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("capm")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/CAPM-img.png`);
    } else if (courseName && courseName.toLowerCase().includes("sp")) {
      setImageSrc(`${process.env.PUBLIC_URL}/images/SP-img.png`);
    } else {
      setImageSrc(`${process.env.PUBLIC_URL}/images/default-course-img.png`);
    }
  }, [courseName]);
  useEffect(() => {
    window.scrollTo(0, 0);  // Scroll to top when component mounts
  }, []);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.user.userType==='student') {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
        // //console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/courses/getCourseDetails`,
          {
            params: { type, courseName },
          }
        );
     
        setCourse(response.data);
        // //console.log(response.data)

        const formattedDates = response.data?.startingDates?.map((date) =>
          new Date(date.startDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            weekday: "long",
          })
        );
        setAvailableDates(formattedDates);
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching course details:", error);
        setLoading(false);
      }
    };

    fetchCourse();
  }, [type, courseName]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const handleAddToCart = () => {
    let dateToSend = "";
    if (type === "simulators") {
      const date = new Date();
      date.setHours(0, 0, 0, 0); // Set time to 00:00:00 for date only
      const formattedDate = formatDate(date);
      setStartDate(formattedDate);
      dateToSend = formattedDate;
      // //console.log("Simulator Start Date:", formattedDate);
    }
  
    if (type === "courses") {
      dateToSend = startDate;
    }
  
    if (type === "courses" && !startDate) {
      setIsModalOpen(true); // Prompt user to select a start date
      return;
    }
  
    if (course) {
      // Check if the user is authenticated
      if (isAuthenticated) {
        axios
          .get(`${process.env.REACT_APP_SERVER_URL}/student/verifyPurchase`, {
            withCredentials: true,
            params: { courseId: course._id, type: 'simulator' },
          })
          .then((response) => {
            if (response.data.hasPurchased) {
              setDialogMessage('You have already purchased this course. Do you still want to add it to your cart?');
              setShowConfirmationDialog(true); // Show confirmation dialog if already purchased
            } else {
              addToCart(dateToSend); // Pass the correct date
            }
          })
          .catch((error) => {
            // console.error(error);
          });
      } else {
        addToCart(dateToSend); // Pass the correct date when not authenticated
      }
    }
  };
  
  const addToCart = (dateToSend) => {
    //console.log("Date to Send:", dateToSend);
    
    const existingCart = cookies["PMI-cart"] ? cookies["PMI-cart"] : [];
  
    // Check if the course with the same title already exists in the cart
    const courseExists = existingCart.some(item => item.title === course.title);
  
    if (courseExists) {
      setDialogMessage('This course is already in your cart. Do you want to add it again?');
      setShowConfirmationDialog(true); // Show confirmation dialog
    } else {
      addToCartConfirmed(dateToSend); // Pass the dateToSend to addToCartConfirmed
    }
  };
  
  // Confirm adding course again
  const handleConfirmAddToCart = () => {
    const dateToSend = type === "courses" ? startDate : new Date().toLocaleDateString();
    addToCartConfirmed(dateToSend); // Pass the correct dateToSend
  };
  
  // Function to actually add the course to the cart
  const addToCartConfirmed = (dateToSend) => {
    const existingCart = cookies["PMI-cart"] ? cookies["PMI-cart"] : [];
  
    const updatedCart = [
      ...existingCart,
      {
        startDate: dateToSend,
        price: course.price,
        duration: course.duration,
        title: course.title,
      },
    ];
  
    // Update the cart in cookies
    setCookie("PMI-cart", JSON.stringify(updatedCart), { path: "/" });
  
    // Show success message
    setIsModalOpen(true);
  
    // Hide the confirmation dialog
    setShowConfirmationDialog(false);
  };
  // Function to handle cancellation of the confirmation dialog
  const handleCancelAddToCart = () => {
    setShowConfirmationDialog(false); // Close the confirmation dialog
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowConfirmationDialog(false); // Close confirmation dialog
  };
  


  return (
    <div>
      <Navbar textColor="text-blue-800" />
      <div className="relative">
        {isModalOpen && (
          <Modal
            title="Success"
            message="The item has been successfully added to your cart!"
            onClose={handleCloseModal}
          />
        )}
        {loading && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}
        <div
          style={{
            background: "linear-gradient(to right, #131F4B 42%, #2C49B1 97%)",
            width: "100%",
          }}
          className="relative flex flex-col lg:flex-row items-center justify-between px-4 lg:px-8 text-white h-auto lg:h-[80%] py-20"
        >
         <ConfirmDialog
        isOpen={showConfirmationDialog}
        title="Confirmation"
        message={dialogMessage}
        onConfirm={handleConfirmAddToCart} // Action to confirm addition to cart
        onCancel={handleCloseModal}
      />
          {/* Text Content */}
          <div className="flex flex-col w-full lg:w-1/2 z-10 text-center lg:text-left">
            <h1 className="text-lg lg:text-xl font-bold mb-2 mt-8 sm:mt-0">
             {course?.title}
            </h1>
            <p className="mb-2 text-gray-200 text-xs lg:text-sm">
              Get your PMI certification now
            </p>
            <p className="my-4 text-sm lg:text-lg">
              Prepare With the Best ® Exam Simulator on the Market For Only $ {course?.price}
            </p>
            <p className="mb-4">
              <span className="font-bold">Price:</span> ${course?.price}
            </p>
            <div className="flex w-full flex-wrap gap-8 justify-center lg:justify-start">
              <div className="md:w-1/4 w-4/5">
                <button
                  className="w-full bg-blue-100 text-gray-900 text-md font-semibold py-2 px-2 mt-6 rounded-lg hover:bg-gray-100 active:bg-blue-200"
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>

          {/*  Image */}
          {imageSrc && imageSrc.trim() !== '' && (
  <img
    src={imageSrc}
    alt=""
    className="absolute top-0 opacity-10 left-1/2 transform -translate-x-1/2 h-full object-cover z-0 hidden lg:block"
    style={{ zIndex: 5 }}
  />
)}

          {/* Right Image */}
          <div className="w-full lg:w-[40%] justify-center lg:justify-end z-4 md:mt-4 lg:mt-0 md:flex flex mt-12">
            <img
              src={`${process.env.PUBLIC_URL}/images/courseDetail-main-img.png`}
              alt="Course Detail Main"
              className="w-4/5 lg:w-full"
            />
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-center text-xl lg:text-2xl font-bold my-8 text-blue-800">
          What's Included
        </h1>
      </div>

      {/* What Included Cards */}
      <div className="flex flex-wrap justify-evenly w-full my-8 gap-4 lg:gap-0">
        {/* Cards */}
        {["Target Score", "Quality Questions", "Realistic Simulation"].map((item, index) => (
          <div
            key={index}
            className="w-2/3 lg:w-1/5 flex flex-col gap-6 border rounded-xl shadow-xl items-center p-4 transform transition-transform duration-300 hover:scale-105 cursor-pointer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${index === 0 ? "Online-icon" : index === 1 ? "working-hour-icon" : "Certificate-icon"}.png`}
              alt={item}
              className="w-1/5 m-auto"
            />
            <h2 className="text-sm lg:text-md text-center w-1/2">{item}</h2>
          </div>
        ))}
      </div>

      {/* Content sections */}
      {[ 
        {
          title: "Prepare to pass Your  Exam",
            content: `PMI exam practice questions created by a team of certified PMP professionals<br/>
              Full-length exam and a separate quiz pool – both offering unlimited retakes.<br/>
             Includes PMBOK® Guide latest edition questions and references.<br/>
         `,
          image: "Course-img-1.png",
        },
        {
          title: "Timed Mode",
          content: `Enhance your time management skills with the Timed Mode. This feature allows you to solve questions within a set time limit, simulating real exam conditions.`,
          image: "simulator-img-main.png",
        },
        {
          title: "Receive Detailed Exam Explanations",
          content: `Get the correct answers with detailed reasoning, directly from our constantly updated practice exam database.`,
          image: "simulator-img-1.png",
        },
        {
          title: "Performance Tracking with Detailed Insights",
          content: `Track your performance with detailed charts. These visual insights help you understand your strengths and areas for improvement.`,
          image: "simulator-result.png",
        },
      ].map((section, idx) => (
        <div
          key={idx}
          className={`flex flex-col lg:flex-row justify-around py-20 ${idx % 2 === 0 ? "bg-gray-100" : ""}`}
        >
          <div className={`flex flex-col gap-4 lg:w-1/3 mx-12 ${idx % 2 === 0 ? "" : "lg:order-2"} data-aos="fade-in"`}>
            <h1 className="font-bold text-xl text-blue-800">{section.title}</h1>
            <p className="text-lg text-gray-700" dangerouslySetInnerHTML={{ __html: section.content }} />
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/images/${section.image}`}
            alt={section.title}
            className={`w-full lg:w-1/3 lg:order-1 ${idx % 2 === 0 ? "order-2" : "order-1"} mt-8 lg:mt-0`}
          />
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default SimulatorDetailPage;
