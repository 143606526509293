import React, { useState, useEffect } from "react";
import Sidebar from "../../utilities/Sidebar";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const CourseDetail = () => {

  const [loading, setLoading] = useState(false);
  const [courseData, setCourseData] = useState("");
  const [error, setError] = useState(null); // State for handling errors
  const params = useParams();
  const courseId = params.courseId;

  const location = useLocation();
  const startDate=location?.state?.startDate;

  
  const getCourseData = async () => {
    try {
      setLoading(true);
  
      // Making the API call
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/student/dashboard/course/courseDetail`,
        {
          params: {
            courseId: courseId,
            startDate: startDate ? startDate : '', // Use startDate if available, otherwise empty
          },
          withCredentials: true,
        }
      );
  
      ////console.log(response); // Log the response for debugging
  
      // Set the course data
      setCourseData(response.data.course);
    } catch (error) {
      // Catch and handle any errors
  
      // Check if the error is due to a response issue from the server (e.g., 4xx or 5xx errors)
      if (error.response) {
        //console.error("Server responded with an error:", error.response);
        // You can show a custom error message based on the response status
        if (error.response.status === 404) {
          //console.error("Course not found!");
          // Optionally, set an error state or show an alert/message to the user
        } else if (error.response.status === 500) {
          //console.error("Server error, please try again later.");
        } else {
          //console.error("An unexpected error occurred:", error.response.data.message || "Unknown error");
        }
      } else if (error.request) {
        // Request was made but no response was received
        //console.error("No response received:", error.request);
      } else {
        // Any other errors (e.g., setting up the request)
        //console.error("Error setting up the request:", error.message);
      }
  
      // Optionally, set an error state to show an error message in the UI
      // setError('Failed to load course data');
    } finally {
      // Always set loading to false when the request is done (either success or error)
      setLoading(false);
    }
  };
  
  // Call the function in useEffect
  useEffect(() => {
    getCourseData();
  }, []);
  

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (timeString) => {
    const date = new Date(
      timeString.replace(
        /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/,
        "$1-$2-$3T$4:$5:$6Z"
      )
    );
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };


  return (
    //  isAuthenticated === null ? (
    // <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
    //   <CircularProgress style={{ color: "blue" }} />
    // </div>
  // ) : isAuthenticated ? (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <Sidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full px-4 md:px-6 relative">
        <div className="w-full md:pt-6 pt-4 md:mt-4">
          <PageNameAndDate pageName={courseData?.title} />
          <div className="flex flex-col gap-2 text-sm">
            {loading && (
              <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
                <CircularProgress style={{ color: "blue" }} />
              </div>
            )}
            {error && <div className="text-red-500">{error}</div>}

            <div className="text-center font-bold text-xl mb-4"><p>{courseData.title}</p></div>

            <div className="flex flex-col sm:flex-row gap-4 w-full">
              <div className="flex flex-col gap-6 w-full sm:w-1/2 m-auto border border-gray-400 rounded-xl p-4 shadow-lg">
                <div className="text-center">
                  <h2 className="font-semibold">Course Info</h2>
                </div>
                <div className="flex justify-between">
                  <p>Duration</p>
                  <p>{courseData.duration || "N/A"}</p>
                </div>
                <div className="flex justify-between">
                  <p>Start Date</p>
                  <p>
                    {courseData.startDate &&
                    !isNaN(new Date(courseData.startDate))
                      ? new Date(courseData.startDate).toLocaleDateString([], {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : "N/A"}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Ending</p>
                  <p>
                    {courseData.endDate && !isNaN(new Date(courseData.endDate))
                      ? new Date(courseData.endDate).toLocaleDateString([], {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      : "N/A"}
                  </p>
                </div>

                <div className="flex justify-between">
                  <p>Mode</p>
                  <p>{courseData.mode || ""}</p>
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md mt-6">
              <h2 className="text-2xl font-semibold mb-4 text-center">
                Class Schedule
              </h2>
              <ul className="space-y-4">
                {courseData.classTimes?.length > 0 ? (
                  courseData.classTimes.map((time, index) => (
                    <li
                      key={index}
                      className="bg-gray-100 p-4 rounded-md hover:shadow-xl shadow-lg"
                    >
                      <div className="flex flex-col md:flex-row justify-between items-center">
                        <div>
                          <p className="text-lg font-medium">
                            Date: {formatDate(time.date)}
                          </p>
                          <p className="text-md text-gray-600">
                            Start Time: {formatTime(time.startTime)}
                          </p>
                          <p className="text-md text-gray-600">
                            End Time: {formatTime(time.endTime)}
                          </p>
                        </div>
                        <div className="mt-4 md:mt-0 w-full md:w-40 text-center md:text-left">
                        {courseData? (
  <a
    href={courseData.meetingLink || "#"}
    target={courseData.meetingLink ? "_blank" : ""}
    rel={courseData.meetingLink ? "noopener noreferrer" : ""}
    className={`bg-blue-500 text-white px-4 py-2 rounded-md transition ${courseData.meetingLink ? "hover:bg-blue-600 cursor-pointer" : "bg-gray-400 cursor-not-allowed"}`}
    onClick={(e) => {
      if (!courseData.meetingLink) {
        e.preventDefault();
      }
    }}
  >
    Join Meeting
  </a>
) : (
  "N/A"
)}

                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="bg-gray-100 p-4 rounded-md text-center text-gray-600">
                    No class times to show
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  // ) : (
  //   <NotLoggedIn />
  );
};

export default CourseDetail;
