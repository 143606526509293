import React, { useState, useEffect } from "react";
import Sidebar from "../utilities/Sidebar";
import DashboardCard from "../utilities/DashboardCard";
import PageNameAndDate from "../utilities/PageNameAndDate";
import axios from "axios";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../../styles/Modal";
import MailIcon from "@mui/icons-material/Mail"; // Import Mail icon
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Import WhatsApp icon
function StudentDashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [modalTitle, setModalTitle] = useState(""); // State for modal title

  useEffect(() => {
  fetchDashboardData();
  }, []);

  const fetchDashboardData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/student/dashboard`, {
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);
        //console.log("Dashboard Data:", response.data);
        setDashboardData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error fetching dashboard data:", error);
      });
  };

  const copyToClipboard = (meetingLink) => {
    if (meetingLink === "N/A" || meetingLink===undefined || !meetingLink) {
      setModalTitle("Error");
      setModalMessage("There is no meeting link available!");
      setModalVisible(true); // Show the modal with error message
      return;
    }
  
    navigator.clipboard
      .writeText(meetingLink)
      .then(() => {
        setModalTitle("Success");
        setModalMessage("Meeting link copied to clipboard!");
        setModalVisible(true); // Show the modal with success message
      })
      .catch((err) => {
        //console.error("Failed to copy link: ", err);
        setModalTitle("Error");
        setModalMessage("Failed to copy the link!");
        setModalVisible(true); // Show the modal with error message
      });
  };
  
  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal
  };

  // Time parsing function
  const parseTime = (timeString) => {
    // //console.log("Raw Time String:", timeString);
    // //console.log("Length of Time String:", timeString.length);

    // Check if the string is of length 16
    if (!timeString || timeString.length !== 16) {
      //console.error("Invalid time format:", timeString);
      return null;
    }

    const year = parseInt(timeString.slice(0, 4), 10);
    const month = parseInt(timeString.slice(4, 6), 10) - 1; // JavaScript months are 0-based
    const day = parseInt(timeString.slice(6, 8), 10);
    const hour = parseInt(timeString.slice(9, 11), 10);
    const minute = parseInt(timeString.slice(11, 13), 10);
    const second = parseInt(timeString.slice(13, 15), 10);

    // Construct date object in UTC
    const date = new Date(Date.UTC(year, month, day, hour, minute, second));

    // Ensure that the date is valid
    if (isNaN(date.getTime())) {
      //console.error("Invalid date created:", date);
      return null;
    }

    return date;
  };

  const formatDate = (date) => date.toLocaleDateString();
  const formatTime = (date) =>
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  // const handleEmailClick = () => {
  //   const email = "mnde.Consultants@gmail.com"; 
  //   const subject = encodeURIComponent("Your Subject Here");
  //   const body = encodeURIComponent("Your message here...");
  //   // This URL opens Gmail directly
  //   window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`, "_blank");
  // };
  const handleEmailClick = () => {
    const email = "mnde.Consultants@gmail.com";
    // const subject = encodeURIComponent("Your Subject Here");
    // const body = encodeURIComponent("Your message here...");
    // This URL opens Gmail directly in the compose window
    window.open(`mailto:${email}`, "_blank");
  };
  
  

  const handleWhatsAppClick = () => {
    const phoneNumber = "+4917665999789"; // Replace with your WhatsApp number
    const message = "Hello!"; // Optional message
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, "_blank");
  };
  return (

    <div className="flex flex-col md:flex-row md:h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden relative">
        <div className="p-4 md:p-6 md:mt-4">
          <PageNameAndDate pageName="Dashboard" />
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 2xl:mb-20">
            <DashboardCard
              label="Enrolled Course"
              value={dashboardData?.courses}
            />
            <DashboardCard
              label="Enrolled Simulators"
              value={dashboardData?.simulators}
            />
            <DashboardCard
              label="Course Completed"
              value={dashboardData?.completedCourses}
            />
            <DashboardCard
              label="Upcoming Week Classes"
              value={dashboardData?.upcomingClassesCount}
            />
          </div>
          {loading && (
            <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )}

          <div className="dashboard mt-6 shadow-md shadow-gray-200 rounded-xl p-6 bg-gray-50 h-[40%] 2xl:h-1/2 overflow-auto">
            <h1 className="text-lg text-gray-800 font-bold mb-2 text-center">
              Upcoming Week Classes
            </h1>
            {dashboardData?.classTimes?.length ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Meeting Link
                    </th>
                    <th className="px-6 py-3 pl-10 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {dashboardData?.classTimes?.reduce((acc, courseData) => {
  const existingCourseIndex = acc.findIndex(item => item.courseId === courseData.courseId);
  
  if (existingCourseIndex === -1) {
    acc.push(courseData); // Add only if courseId not found
  }
  
  return acc;
}, []).map((courseData, courseIndex) => 
  courseData.classTimes?.map((classTime, timeIndex) => {
    const startTimeObj = parseTime(classTime.startTime);
    const endTimeObj = parseTime(classTime.endTime);

    if (!startTimeObj || !endTimeObj) {
      //console.error("Invalid time data:", classTime.startTime, classTime.endTime);
      return null; // Skip rendering this row if times are invalid
    }

    const startTime = formatTime(startTimeObj);
    const endTime = formatTime(endTimeObj);
    const date = formatDate(startTimeObj);

    return (
      <tr key={timeIndex}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-500">
          <div className="flex items-center gap-2">
            <FileCopyIcon
              className="h-5 w-5 text-blue-500 cursor-pointer hover:text-blue-700 transition"
              onClick={() => copyToClipboard(courseData.meetingLink)}
            />
            <a
              href={courseData.meetingLink}
              target="_blank"
              rel="noopener noreferrer"
              className="block underline truncate hover:text-blue-700 transition"
              style={{ maxWidth: "200px" }}
            >
              {courseData.meetingLink || "N/A"}
            </a>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
          {date}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
          {`${startTime} - ${endTime}`}
        </td>
      </tr>
    );
  })
)}

                </tbody>
              </table>
            ) : (
              <p className="text-center text-gray-600">
                No upcoming classes this week.
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Modal for copying link */}
      {modalVisible && (
        <Modal
          title={modalTitle}
          message={modalMessage}
          onClose={handleCloseModal}
        />
      )}
      <div className="fixed bottom-4 right-4 flex flex-col items-center space-y-3">
      <div
  onClick={handleEmailClick}
  style={{ fontSize: "34px", "@media (min-width: 1536px)": { fontSize: "48px" }}}
  className="bg-white rounded-full px-2 hover:scale-105 shadow-md cursor-pointer hover:bg-gray-100 transition"
>
  <MailIcon 
    style={{ color: "#DB4437", fontSize: "inherit" }} 
  />
</div>


        {/* <div
          onClick={handleWhatsAppClick}
          className="bg-white rounded-full hover:scale-105 p-2 shadow-md cursor-pointer hover:bg-gray-100 transition"
        >
          <WhatsAppIcon style={{ fontSize: "34px", color: "#25D366" }} />
        </div> */}
      </div>
    </div>
 
  );
}

export default StudentDashboard;
