import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Payment from "./Components/Payment/Payment";
import LandingPage from "./Components/LandingPage/LandingPage";
import CoursesPage from "./Components/Courses/CoursesPage";
import RegisterPage from "./Components/Authentication/Register/RegisterPage";
import LoginPage from "./Components/Authentication/Register/LoginPage";
import CourseDetailPage from "./Components/Courses/CourseDetail/CourseDetailPage";
import StudentDashboard from './Components/Dashboard/StudentDashboard/StudentDashboard';
import Courses from './Components/Dashboard/Courses/Courses';
import Simulators from './Components/Dashboard/StudentDashboard/Simulator/Simulators';
import CourseDetail from './Components/Dashboard/Courses/CourseDetail/CourseDetail';
import InstructorDashboard from './Components/Dashboard/InstructorDashboard/InstructorDashboard';
import StudentsList from './Components/Dashboard/InstructorDashboard/Students/StudentsList';
import StudentDetail from './Components/Dashboard/InstructorDashboard/Students/StudentDetail';
import Availability from './Components/Dashboard/InstructorDashboard/Availability/Availability';
import Sessions from './Components/Dashboard/InstructorDashboard/Sessions/Sessions';
import SimulatorModuleList from './Components/Dashboard/StudentDashboard/Simulator/SimulatorDetail/SimulatorModulesList';
import SimulatorModule from './Components/Dashboard/StudentDashboard/Simulator/SimulatorDetail/SimulatorModule';
import PaymentsAndBilling from './Components/Dashboard/StudentDashboard/Payment/Payments';
import InstructorCourses from './Components/Dashboard/InstructorDashboard/Courses/InstructorCourses';
import InstructorSimulatorDetail from './Components/Dashboard/InstructorDashboard/Courses/SimulatorModules';
import SimulatorModuleDetail from './Components/Dashboard/InstructorDashboard/Courses/SimulatorModuleDetail';
import InstructorCourseDetail from './Components/Dashboard/InstructorDashboard/Courses/InstructorCourseDetail';
import SimulatorDetailPage from './Components/Courses/CourseDetail/SimulatorDetailPage';
import Profile from './Components/Dashboard/StudentDashboard/Profile/Profile';
import Payments from './Components/Dashboard/InstructorDashboard/Payment/Payments';
import ShowCourseStudents from './Components/Dashboard/InstructorDashboard/Courses/ShowCourseStudents';
import AddSimulatorForm from './Components/Dashboard/InstructorDashboard/Courses/AddCourse/AddSimulator';
import AddCourse from './Components/Dashboard/InstructorDashboard/Courses/AddCourse/AddCourse';
import AboutUs from './Components/AboutUs';
import PrivacyPolicy from './Components/PrivacyPolicy';
import OurMission from './Components/OurMission';
import TermsAndConditions from './Components/TermsAndConditions';
import ProtectedInstructorRoute from './Components/Dashboard/ProtectedInstructorRoute';
import ProtectedStudentRoute from './Components/Dashboard/ProtectedStudentRoute';
import Success from './Success';
import Cancel from './Cancel';
import VerifyEmail from './Components/Authentication/VerifyEmail';
import ResetPasswordPage from './Components/Authentication/ResetPasswordPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} /> {/* Default route */}
        <Route path="/courses" element={<CoursesPage />} />
        <Route path="/simulators" element={<CoursesPage />} />
        <Route path="/instructor/dashboard/payments" element={<Payments />} />
        <Route path="/About-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/Our-Mission" element={<OurMission />} />
        <Route path="/Terms-and-condition" element={<TermsAndConditions />} />
        
        <Route path="/:type/dashboard/profile" element={<Profile />} />
        <Route path="/Payments" element={<Payment />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/courses/courseDetail/:type/course/:courseName" element={<CourseDetailPage />} />
        <Route path="/courses/courseDetail/:type/simulator/:courseName" element={<SimulatorDetailPage />} />
        
        {/* Protected Student Routes */}
        <Route path="/student/dashboard" element={<ProtectedStudentRoute element={<StudentDashboard />} />} />
        <Route path="/student/dashboard/courses" element={<ProtectedStudentRoute element={<Courses />} />} />
        <Route path="/student/dashboard/payments-and-billing" element={<ProtectedStudentRoute element={<PaymentsAndBilling />} />} />
        <Route path="/student/dashboard/simulators" element={<ProtectedStudentRoute element={<Simulators />} />} />
        <Route path="/student/dashboard/simulators/modules/:simulatorTitle" element={<ProtectedStudentRoute element={<SimulatorModuleList />} />} />
        <Route path="/student/dashboard/simulators/modules/:simulatorTitle/:moduleTitle" element={<ProtectedStudentRoute element={<SimulatorModule />} />} />
        <Route path="/student/dashboard/course/detail/:courseId" element={<ProtectedStudentRoute element={<CourseDetail />} />} />

        {/* Protected Instructor Routes */}
        <Route path="/instructor/dashboard" element={<ProtectedInstructorRoute element={<InstructorDashboard />} />} />
        <Route path="/instructor/dashboard/students" element={<ProtectedInstructorRoute element={<StudentsList />} />} />
        <Route path="/instructor/dashboard/students/studentDetail/:id" element={<ProtectedInstructorRoute element={<StudentDetail />} />} />
        <Route path="/instructor/dashboard/courses" element={<ProtectedInstructorRoute element={<InstructorCourses />} />} />
        <Route path="/instructor/dashboard/availability" element={<ProtectedInstructorRoute element={<Availability />} />} />
        <Route path="/instructor/dashboard/sessions" element={<ProtectedInstructorRoute element={<Sessions />} />} />
        <Route path="/instructor/dashboard/simulator/detail/:id" element={<ProtectedInstructorRoute element={<InstructorSimulatorDetail />} />} />
        <Route path="/instructor/dashboard/simulator/detail/module/:id" element={<ProtectedInstructorRoute element={<SimulatorModuleDetail />} />} />
        <Route path="/instructor/dashboard/course/detail/:id" element={<ProtectedInstructorRoute element={<InstructorCourseDetail />} />} />
        <Route path="/instructor/dashboard/course/showStudentsByCourse/:id/:StartDate" element={<ProtectedInstructorRoute element={<ShowCourseStudents />} />} />
        <Route path="/instructor/dashboard/courses/AddSimulator" element={<ProtectedInstructorRoute element={<AddSimulatorForm />} />} />
        <Route path="/instructor/dashboard/courses/AddCourse" element={<ProtectedInstructorRoute element={<AddCourse />} />} />
        
        <Route path="/Success" element={<Success />} />
        <Route path="/Cancel" element={<Cancel />} />
      </Routes>
    </Router>
  );
}

export default App;
