import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InstructorSidebar from "../../../utilities/InstructorSidebar";
import InputField from "../../../../styles/InputField";
import Modal from "../../../../styles/Modal";
import { useLocation } from "react-router-dom";

const AddSimulator = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    duration: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [simulator, setSimulator] = useState("");
  const { edit, courseName, type } = location.state || {};

  // Fetch simulator details if editing
  useEffect(() => {
    if (edit && courseName && type) {
      const fetchCourse = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/courses/getCourseDetails`,
            {
              params: { type, courseName },
            }
          );
          const fetchedSimulator = response.data;
          // Set form data with fetched simulator details
          setFormData({
            title: fetchedSimulator.title,
            price: fetchedSimulator.price.toString(), // Convert price to string for form input
            duration: fetchedSimulator.duration.replace(" weeks", ""), // Remove "weeks" for editing
          });
          setLoading(false);
        } catch (error) {
          //console.error("Error fetching course details:", error);
          setLoading(false);
        }
      };
      fetchCourse();
    }
  }, [edit, courseName, type]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApiError("");
    if (name === "price") {
      // Allow float numbers in price
      const regex = /^[0-9]*[.,]?[0-9]*$/; // Allows float numbers with optional decimal point
      if (regex.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "duration") {
      const regex = /^[0-9\b]+$/; // Only allows digits for duration
      if (value === "" || regex.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!formData.title) validationErrors.title = "Title is required";
    if (!formData.price) {
      validationErrors.price = "Price is required";
    } else if (isNaN(parseFloat(formData.price))) {
      validationErrors.price = "Price must be a valid number";
    }
    if (!formData.duration) {
      validationErrors.duration = "Duration is required";
    } else if (!/^[0-9]+$/.test(formData.duration)) {
      validationErrors.duration = "Duration must be a number";
    }
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const { duration, ...restOfData } = formData;
      const cleanedData = {
        ...restOfData,
        price: parseFloat(formData.price), // Convert price back to float for submission
        duration: `${duration} weeks`,
      };

      try {
        setLoading(true);
        if (edit) {
          // If edit mode, update simulator
          await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/simulator/updateSimulator`,
            { ...cleanedData, courseName, type },
            { withCredentials: true }
          );
        } else {
          // If not editing, add new simulator
          await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/simulator/addSimulator`,
            cleanedData,
            { withCredentials: true }
          );
        }
        setLoading(false);
        setShowModal(true);
        setFormData({
          title: "",
          price: "",
          duration: "",
        });
      } catch (error) {
        setLoading(false);
        if (error.response?.status === 400) {
          setApiError(error.response.data.message);
        }
        //console.error("Error submitting simulator:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full md:mt-0 relative">
        <div className="w-full p-6">
          <PageNameAndDate
            pageName={edit ? "Update Simulator" : "Add Simulator"}
            showBackButton={true}
            backLink={`/instructor/dashboard/courses`}
          />
        </div>

        <div className="p-6 pt-8 rounded-lg space-y-8 bg-slate-200 mx-auto w-1/2">
          <h1 className="font-semibold text-2xl text-center">
            {edit ? "Update Simulator" : "Add Simulator"}
          </h1>
          <form onSubmit={handleSubmit} className="space-y-8">
            <InputField
              label="Title"
              type="text"
              id="title"
              name="title"
              autoComplete="off"
              value={formData.title}
              error={errors.title}
              onChange={handleInputChange}
              top="6"
            />
            <InputField
              label="Price ($)"
              type="text"
              id="price"
              name="price"
              autoComplete="off"
              value={formData.price}
              error={errors.price}
              onChange={handleInputChange}
              top="6"
            />
            <InputField
              label="Duration (weeks)"
              type="text"
              id="duration"
              name="duration"
              autoComplete="off"
              value={formData.duration}
              error={errors.duration}
              onChange={handleInputChange}
              top="6"
            />
            {apiError && <p className="text-red-500">{apiError}</p>}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 hover:shadow-lg active:bg-white active:text-blue-800 text-white text-sm py-2 px-4 mt-4 rounded-2xl"
              >
                {edit ? "Update Simulator" : "Add Simulator"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      )}
      {showModal && (
        <Modal
          title="Success"
          message={edit ? "Simulator updated successfully!" : "Simulator added successfully!"}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AddSimulator;
