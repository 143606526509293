import React from 'react';
import Navbar from './styles/Navbar';
import Footer from './LandingPage/Footer';

const AboutUs = () => {
  return (
    <div>
      <Navbar textColor="text-blue-800"></Navbar>

      <div className="bg-gray-100 py-10 px-5 md:px-20">
        <div className="container mx-auto">
          {/* Header */}
          <header className="text-center mb-12">
            <h1 className="text-3xl sm:text-4xl font-bold text-blue-900 mb-4">About Us</h1>
            <p className="text-base sm:text-lg text-gray-700">
              Empowering professionals with cutting-edge project management training.
            </p>
          </header>

          {/* Mission */}
          <section className="mb-12">
            <h2 className="text-2xl sm:text-3xl font-semibold text-blue-800 mb-4">Our Mission</h2>
            <p className="text-sm sm:text-base text-gray-600 leading-relaxed">
              At MndE Consultants, our mission is to provide top-quality, accessible project management training to help professionals excel in their careers. We offer comprehensive courses covering PMI PMP, PgMP, and Introduction to Project Management, ensuring that our students gain the skills and knowledge needed to lead successful projects.
            </p>
          </section>

          {/* Training Features */}
          <section className="bg-white rounded-lg shadow-lg p-6 mb-12">
            <h2 className="text-2xl sm:text-3xl font-semibold text-blue-800 mb-4">What We Offer</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex items-start space-x-4 mb-6">
                <div className="flex items-center justify-center bg-blue-900 text-white rounded-full md:p-2 p-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="md:w-6 md:h-6 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold text-blue-900">Instructor-Led Online Training</h3>
                  <p className="text-gray-600 mt-2 text-sm sm:text-base">
                    Learn from experienced project management professionals through interactive online classes that offer real-time feedback and guidance.
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4 mb-6">
                <div className="flex items-center justify-center bg-blue-900 text-white rounded-full  md:p-2 p-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="md:w-6 md:h-6 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold text-blue-900">Comprehensive Simulators</h3>
                  <p className="text-gray-600 mt-2 text-sm sm:text-base">
                    Test your knowledge with our state-of-the-art simulators that include timed practice exams to help you prepare for certification.
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4 mb-6">
                <div className="flex items-center justify-center bg-blue-900 text-white rounded-full md:p-2 p-1 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="md:w-6 md:h-6 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold text-blue-900">Detailed Results and Analytics</h3>
                  <p className="text-gray-600 mt-2 text-sm sm:text-base">
                    Access detailed results and performance analytics to track your progress and identify areas for improvement.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Team Section */}
          <section>
            <h2 className="text-2xl sm:text-3xl font-semibold text-blue-800 mb-4">Our Expertise</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              <div className="bg-white rounded-lg shadow-lg p-6">
                {/* <h3 className="text-lg sm:text-xl font-semibold text-blue-900 mb-2">John Doe</h3> */}
                <p className="text-gray-600 text-sm sm:text-base">
                  Lead Instructor with over 15 years of experience in project management and a passion for teaching.
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-lg p-6">
                {/* <h3 className="text-lg sm:text-xl font-semibold text-blue-900 mb-2">Jane Smith</h3> */}
                <p className="text-gray-600 text-sm sm:text-base">
                  Expert in PMI certifications and dedicated to providing comprehensive support to all students.
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-lg p-6">
                {/* <h3 className="text-lg sm:text-xl font-semibold text-blue-900 mb-2">Emily Johnson</h3> */}
                <p className="text-gray-600 text-sm sm:text-base">
                  Simulator Specialist with a focus on creating realistic and effective practice environments.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <Footer></Footer>
    </div>
  );
};

export default AboutUs;
