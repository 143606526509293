import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

const ContactUs = () => {
  const [state, handleSubmit] = useForm("xdknjyyl");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Reset error message when user starts typing
    setErrorMessage('');
  };

  const validateForm = () => {
    // Check if all fields are filled
    if (!formData.name.trim() || !formData.email.trim() || !formData.message.trim()) {
      return 'All fields must be filled.';
    }

    // Check if email format is correct
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      return 'Please enter a valid email address.';
    }

    return null; // No errors
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const error = validateForm(); // Validate the form
    if (error) {
      setErrorMessage(error); // Set error message
      return; // Exit the function if validation fails
    }

    setLoading(true); // Start loading

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/student/sendMail`, formData);

      if (response.status === 200) {
        setShowSuccess(true);
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      } else {
        //console.error('Error sending email:', response.statusText);
        setErrorMessage('Failed to send message. Please try again later.'); // Set error message for non-200 status
      }
    } catch (error) {
      //console.error('Error sending email:', error);
      setErrorMessage('An error occurred while sending your message.'); // Set error message for catch block
    } finally {
      setLoading(false); // Stop loading in both success and error cases
    }
  };

  return (
    <div className="flex flex-col items-center py-10 md:py-20 bg-bg_gray">
      <form onSubmit={handleFormSubmit} className="w-full px-4 md:px-12">
        <div className="text-center">
          <h1 className="text-2xl md:text-3xl font-bold">
            Get in <span className="text-blue-500">Touch</span>
          </h1>
        </div>
        <div className="text-center mb-6 md:mb-12">
          <p className="text-sm md:text-base">Any Questions or Remarks? Just write us a message</p>
        </div>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/contact-us-image.png`}
              alt="Contact Us"
              className="w-full md:w-3/4"
              rel="preload"
            />
          </div>
          <div className="w-full md:w-1/2 2xl:mt-14 flex flex-col gap-4">
            <input
              type="text"
              name="name"
              value={formData.name} // Set input value from state
              onChange={handleChange} // Update state on change
              placeholder="Enter name"
              className="border border-gray-300 rounded-md p-2 w-full text-sm md:text-base"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email} // Set input value from state
              onChange={handleChange} // Update state on change
              placeholder="Enter Email"
              className="border border-gray-300 rounded-md p-2 w-full text-sm md:text-base"
              required
            />
            <textarea
              name="message"
              value={formData.message} // Set textarea value from state
              onChange={handleChange} // Update state on change
              placeholder="Enter Description"
              className="border border-gray-300 rounded-md p-2 w-full h-32 resize-none text-sm md:text-base"
              required
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>} {/* Display custom error message */}
          </div>
        </div>
        <div className='text-center'>
          <button
            type="submit"
            disabled={state.submitting || loading} // Disable button when submitting or loading
            className='w-11/12 md:w-1/2 m-auto text-center bg-blue-700 text-white rounded-lg p-2 mt-8 cursor-pointer hover:bg-blue-500 active:text-blue-600 active:bg-blue-200 font-semibold'
          >
            {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : 'Send'} {/* Show loader in button */}
          </button>
        </div>
      </form>
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold text-green-600">Success!</h2>
            <p className="mt-2">Your message has been sent successfully.</p>
            
            <button
              onClick={() => setShowSuccess(false)}
              className="mt-4 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
