import React from 'react';
import Navbar from './styles/Navbar';
import Footer from './LandingPage/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
           <Navbar textColor='text-blue-800'></Navbar>

    <div className="container mx-auto p-6 pt-8 pb-12  bg-gray-100">
      <h1 className="text-3xl text-blue-800 text-center  font-bold mb-6">Privacy Policy</h1>
      <p className="text-gray-700 mb-6">
        At MndE Consultants, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide when using our website.
      </p>
      
      <h2 className="text-2xl text-blue-800 font-semibold mb-4">1. Information We Collect</h2>
      <p className="text-gray-600 mb-4">
        We collect information that you provide directly to us when you register for an account, purchase a course, schedule an online meeting, or interact with our simulators. This may include:
      </p>
      <ul className="list-disc list-inside text-gray-600 mb-6">
        <li>Personal Information: name, email address, phone number</li>
        <li>Payment Information: credit card or payment service details (processed by third-party providers like Stripe and PayPal)</li>
        <li>Technical Data: IP address, browser type, device information</li>
      </ul>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">2. How We Use Your Information</h2>
      <p className="text-gray-600 mb-6">
        We use your information to:
      </p>
      <ul className="list-disc list-inside text-gray-600 mb-6">
        <li>Process payments and enrollments in courses</li>
        <li>Schedule online meetings with instructors</li>
        <li>Provide access to simulators for test preparation</li>
        <li>Improve our services and user experience</li>
        <li>Communicate with you regarding updates, offers, and support</li>
      </ul>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">3. Cookies</h2>
      <p className="text-gray-600 mb-6">
        We use cookies to enhance your browsing experience. Cookies are small data files stored on your device. You can manage your cookie preferences in your browser settings, though disabling cookies may affect some functionality on our website.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">4. Third-Party Services</h2>
      <p className="text-gray-600 mb-6">
        We may share your information with third-party services such as payment processors (PayPal, Stripe) to complete transactions. These services have their own privacy policies, and we encourage you to review them.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">5. Data Security</h2>
      <p className="text-gray-600 mb-6">
        We implement industry-standard security measures to protect your personal data from unauthorized access. However, no online transmission is completely secure, and we cannot guarantee the absolute security of your information.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">6. Your Rights</h2>
      <p className="text-gray-600 mb-6">
        You have the right to access, correct, or delete your personal information at any time. If you wish to exercise these rights, please contact our support team.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">7. Changes to This Policy</h2>
      <p className="text-gray-600 mb-6">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this policy periodically.
      </p>

      <p className="text-gray-600">
        If you have any questions regarding our privacy practices, feel free to contact us at mnde.Consultants@gmail.com.
      </p>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
