import React from 'react';
import Navbar from './styles/Navbar';
import Footer from './LandingPage/Footer';

const OurMission = () => {
  return (
    <div>
   <Navbar textColor='text-blue-800'></Navbar>
    <div className="container mx-auto p-6 text-start pt-8 pb-12 bg-gray-100 ">
      <h1 className="text-3xl text-blue-800 text-center font-bold mb-6">Our Mission</h1>
      <p className="text-gray-700 mb-6">
        At MndE Consultants, our mission is to empower professionals to achieve their project management certification goals. We strive to offer high-quality education, tools, and resources that help individuals succeed in their career journeys.
      </p>

      <h2 className="text-2xl text-blue-800  font-semibold mb-4">1. Comprehensive Learning</h2>
      <p className="text-gray-600 mb-6">
        We provide a wide range of courses designed to cover every aspect of project management. Whether you’re pursuing a PMP, PgMP, or foundational knowledge, we’ve built our curriculum to cater to different levels of expertise.
      </p>

      <h2 className="text-2xl text-blue-800  font-semibold mb-4">2. Professional Instructors</h2>
      <p className="text-gray-600 mb-6">
        Our team consists of certified instructors who bring years of industry experience. They are committed to guiding you through every stage of your certification preparation, offering personalized support and guidance.
      </p>

      <h2 className="text-2xl text-blue-800  font-semibold mb-4">3. State-of-the-Art Simulators</h2>
      <p className="text-gray-600 mb-6">
        Our simulators are designed to mimic real certification exam conditions, providing you with a comprehensive practice environment. Our goal is to ensure that you walk into the exam room confident and prepared.
      </p>

      <h2 className="text-2xl text-blue-800  font-semibold mb-4">4. Flexibility and Accessibility</h2>
      <p className="text-gray-600 mb-6">
        We understand the busy schedules of professionals. That’s why our courses are accessible anytime, anywhere. Whether you’re studying at home or on the go, we make it easy to fit learning into your lifestyle.
      </p>

      <h2 className="text-2xl text-blue-800  font-semibold mb-4">5. Commitment to Excellence</h2>
      <p className="text-gray-600 mb-6">
        We are continually improving our platform, incorporating the latest teaching methods, technologies, and feedback from our users to ensure the best possible learning experience.
      </p>

      <p className="text-gray-700">
        Join us on our mission to shape the future of project management professionals by providing unparalleled training and support.
      </p>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default OurMission;
