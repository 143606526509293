// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your stylesheet */
.quill-editor {
    text-align: center; /* Center text by default */
  }
  
  /* You may need to adjust alignment in the toolbar as well */
  .quill-editor .ql-editor {
    text-align: left; /* Ensure editor text is left aligned if you want */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/InstructorDashboard/Courses/AddCourse/AddCourse.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,kBAAkB,EAAE,2BAA2B;EACjD;;EAEA,4DAA4D;EAC5D;IACE,gBAAgB,EAAE,mDAAmD;EACvE","sourcesContent":["/* Add this CSS to your stylesheet */\r\n.quill-editor {\r\n    text-align: center; /* Center text by default */\r\n  }\r\n  \r\n  /* You may need to adjust alignment in the toolbar as well */\r\n  .quill-editor .ql-editor {\r\n    text-align: left; /* Ensure editor text is left aligned if you want */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
