import React from 'react';

const Modal = ({ message, onClose, onConfirm, onSave, title }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center md:w-1/2 w-4/5 text-xs md:text-lg">
        <h2 className="md:text-xl text-lg font-semibold mb-4">{title}</h2>
        <p>{message}</p>
        <div className="mt-8 flex justify-center gap-8 w-full">
          <button
            className="mr-2 px-4 py-2 text-base self-center bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={onConfirm}
          >
            Go Without Saving
          </button>
          {/* <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            onClick={onSave}
          >
            Save and Navigate
          </button> */}
          <button
          className="px-4 py-2 text-base self-center bg-gray-500 text-white rounded hover:bg-gray-700"
          onClick={onClose}
        >
          Cancel
        </button>
        </div>
        {/* <button
          className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
          onClick={onClose}
        >
          Cancel
        </button> */}
      </div>
    </div>
  );
};

export default Modal;
