import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InstructorSidebar from "../../../utilities/InstructorSidebar";
import InputField from "../../../../styles/InputField";
import Modal from "../../../../styles/Modal";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import './AddCourse.css'
import { useLocation } from "react-router-dom"; // Import useLocation
import Switch from "@mui/material/Switch"; // Import Switch


const AddCourse = () => {
  const location = useLocation(); // Use location to get the state
////console.log(location)
  const [loading, setLoading] = useState(false);
  const[showModal,setShowModal]=useState(false);
  const[modalMessage,setModalMessage]=useState('');
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    Features: "", // Use a string for HTML content instead of an array
    courseId: "",
    simulators: [],
    price: "",
    mode: "",
    duration: "",
    simulatorId: "",
    startingDates: [],
    hoursOfTraining: "",
    courseStatus: true, // By default, the course is enabled

  });
  const [simulators, setSimulators] = useState([]);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/getSimulatorTitles`, {
        withCredentials: true,
      })
      .then((response) => {
        
        setSimulators(response.data);
      })
      .catch((error) => {
        //console.error("Error fetching simulators:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    // Check if we are in edit mode
    const { edit, courseName,type } = location.state || {};
    if (edit && courseName && type) {
      // Fetch course data for editing
      axios.get(
        `${process.env.REACT_APP_SERVER_URL}/courses/getCourseDetails`,
        {
          params: { type  , courseName },
        }
      )
        .then((response) => {
          // console.log(response)
          const courseData = response.data;
          setFormData({
            title: courseData.title,
            description: courseData.description,
            Features: courseData.Features,
            courseId: courseData._id,
            simulators: courseData.simulators,
            price: courseData.price,
            mode: courseData.mode,
            duration: courseData.duration.replace(" weeks", ""), // Assuming duration comes as 'X weeks'
            simulatorId: courseData.simulatorId,
            startingDates: courseData.startingDates,
            hoursOfTraining: courseData.hoursOfTraining,
            courseStatus:courseData.courseStatus
          });
        })
        .catch((error) => {
          //console.error("Error fetching course data:", error);
        });
    }


  }, []);

  // Handle input change for non-array fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
setApiError('')
    // Restrict non-numeric characters in price and duration fields
    if (name === "price" || name === "duration") {
      // Allow only numbers and periods (for decimals)
      if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
  };
  const handleQuillChange = (content, delta, source, editor) => {
    setFormData((prevData) => ({
        ...prevData,
        Features: editor.getHTML(), // Store HTML content in Features field
    }));
    setErrors((prevErrors) => ({
        ...prevErrors,
        Features: "", // Clear any previous error
    }));
};
const modules = {
  toolbar: [
    [{ 'header': [1, 2,3,4, false] }], // Add heading styles
    ['bold', 'italic', 'underline', 'strike'], // Formatting buttons
    [{ 
      'color': [
        '#000000', '#2D4BB4', '#4673fa', '#FF0000', '#00FF00', '#0000FF',
        '#FFFF00', '#00FFFF', '#FF00FF', '#FFA500', '#800080', '#808080',
        '#FFFFFF', '#FF6347', '#FF4500', '#FFD700', '#ADFF2F', '#7FFF00',
        '#32CD32', '#00FA9A', '#00CED1', '#4682B4', '#1E90FF', '#6A5ACD',
        '#8A2BE2', '#FF69B4', '#B22222', '#FFB6C1', '#DB7093', '#FFC0CB',
        '#FFE4E1', '#FFEBCD', '#FFF8DC', '#F5DEB3', '#FFEFD5', '#FFF5EE',
        '#F5F5F5', '#DCDCDC', '#D3D3D3', '#C0C0C0', '#A9A9A9', '#808080'
      ] 
    }, { 
      'background': [
        '#000000', '#2D4BB4', '#4673fa', '#FF0000', '#00FF00', '#0000FF',
        '#FFFF00', '#00FFFF', '#FF00FF', '#FFA500', '#800080', '#808080',
        '#FFFFFF', '#FF6347', '#FF4500', '#FFD700', '#ADFF2F', '#7FFF00',
        '#32CD32', '#00FA9A', '#00CED1', '#4682B4', '#1E90FF', '#6A5ACD',
        '#8A2BE2', '#FF69B4', '#B22222', '#FFB6C1', '#DB7093', '#FFC0CB',
        '#FFE4E1', '#FFEBCD', '#FFF8DC', '#F5DEB3', '#FFEFD5', '#FFF5EE',
        '#F5F5F5', '#DCDCDC', '#D3D3D3', '#C0C0C0', '#A9A9A9', '#808080'
      ] 
    }],    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link'], // Link and image options
    [{ 'align': [] }], // Text alignment
    ['clean'], // Clear formatting button
  ],
};


  // Handle input change for array fields
  const handleArrayChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.split(', ')
    }));
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
  };
  const handleStatusChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      courseStatus: e.target.checked, // Switch checked status will be used
    }));
  };

  // Validate the form
  const validateForm = () => {
    let validationErrors = {};
  ////console.log(formData)
    // Validate text fields
    if (!formData.title.trim()) validationErrors.title = "Title is required";
    if (!formData.description.trim()) validationErrors.description = "Description is required";
    if (!formData.Features || !formData.Features.trim()) {
      validationErrors.Features = "Features are required";
    }
      
    const priceStr = String(formData.price).trim(); // Convert to string
    if (!priceStr) validationErrors.price = "Price is required";
    else if (!/^[0-9]+[.,]?[0-9]*$/.test(priceStr)) validationErrors.price = "Price must be a number";
    if (!formData.duration.trim()) validationErrors.duration = "Duration is required";
    else if (!/^[0-9]+[.,]?[0-9]*$/.test(formData.duration)) validationErrors.duration = "Duration must be a number";
    // if (!formData.simulatorId) validationErrors.simulatorId = "Please select a simulator";
  
    return validationErrors;
  };
  
  
  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
        const { duration, ...restOfData } = formData;
        const cleanedData = {
          ...restOfData,
          duration: `${duration} weeks`
        }
        if (location?.state?.edit) {  
          // Update course data
          setLoading(true)
          axios
            .put(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/updateCourse/${location.state.courseId}`, cleanedData, {
              withCredentials: true,
            })
            .then((response) => {
              ////console.log(response)
              setLoading(false);
              setModalMessage("Course updated Successfully")
              setShowModal(true);
              setFormData({
                title: "",
                description: "",
                Features: "",
                courseId: "",
                simulators: [],
                price: "",
                mode: "",
                duration: "",
                simulatorId: "",
                startingDates: [],
                hoursOfTraining: "",
              });
              ////console.log("Course updated successfully", response.data);
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status === 400) {
                setApiError(error.response.data.message);
              }
              //console.error("Error updating course:", error);
            });
        }
        else{
          setLoading(true);
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/course/addCourse`, cleanedData, {
          withCredentials: true,
        })
        .then((response) => {
          setLoading(false);
          setModalMessage("Course Added Successfully")
          setShowModal(true);
          setFormData({
            title: "",
            description: "",
            Features: "",
            
            courseId: "",
            simulators: [],
            price: "",
            mode: "",
            duration: "",
            simulatorId: "",
            startingDates: [],
            hoursOfTraining: "",
          });
  
          ////console.log("Course added successfully", response.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 400) {
            setApiError(error.response.data.message);
          }
          //console.error("Error adding course:", error);
        });
        }
      
    } else {
      setErrors(validationErrors);
    }
  };

  return (
  
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full md:mt-0 relative">
        <div className="w-full p-4 md:p-6 md:mt-4">
          <PageNameAndDate
            pageName={location?.state?.edit?"Update Course":"Add Course"}
            showBackButton={true}
            backLink="/instructor/dashboard/courses"
          />
        </div>

        <div className="p-6 pt-8 rounded-lg space-y-8 bg-slate-200 mx-auto w-full md:w-3/4 lg:w-4/5">
          <h1 className="font-semibold text-2xl text-center">{location?.state?.edit?"Update Course":"Add Course"}</h1>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <InputField
                label="Title"
                type="text"
                id="title"
                name="title"
                autoComplete="off"
                value={formData.title}
                error={errors.title}
                onChange={handleInputChange}
              />
              <InputField
                label="Description"
                type="text"
                id="description"
                name="description"
                autoComplete="off"
                value={formData.description}
                error={errors.description}
                onChange={handleInputChange}
              />
           
             
              <div className="col-span-1 md:col-span-2">
                <label className="block text-gray-700 text-sm font-bold mb-2">Features</label>
                <ReactQuill
          theme="snow"
          value={formData.Features}
          onChange={handleQuillChange}
          modules={modules}
          className="quill-editor" // Custom class for styling
        />
                {errors.Features && <p className="text-red-600">{errors.Features}</p>}
              </div>
             
              <InputField
                label="Price ($)"
                type="text"
                id="price"
                name="price"
                autoComplete="off"
                value={formData.price}
                error={errors.price}
                onChange={handleInputChange}
              />
              <InputField
                label="Duration (weeks)"
                type="text"
                id="duration"
                name="duration"
                autoComplete="off"
                value={formData.duration}
                error={errors.duration}
                onChange={handleInputChange}
              />
               <InputField
                label="Hours of Training (optional)"
                type="text"
                id="hoursOfTraining"
                name="hoursOfTraining"
                autoComplete="off"
                value={formData.hoursOfTraining}
                onChange={handleInputChange}
              />
              <div className="col-span-1">
                <select
                  id="simulatorId"
                  name="simulatorId"
                  value={formData.simulatorId}
                  onChange={handleInputChange}
                  className="w-full bg-gray-100 text-gray-500 p-2 border border-gray-300 rounded-lg"
                >
                  <option value="">Select a simulator</option>
                  {simulators.map((simulator) => (
                    <option key={simulator.id} value={simulator.id}>
                      {simulator.title}
                    </option>
                  ))}
                </select>
                {errors.simulatorId && <p className="text-red-600">{errors.simulatorId}</p>}
              </div>
            </div>
            <div className="col-span-1 md:col-span-2">
                <label className="block text-gray-700 text-sm font-bold mb-2">Course Status</label>
                <Switch
                  checked={formData.courseStatus}
                  onChange={handleStatusChange}
                  color="primary"
                  name="courseStatus"
                  inputProps={{ "aria-label": "course status switch" }}
                />
                <span>{formData.courseStatus ? "Enabled" : "Disabled"}</span>
              </div>
            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : location?.state?.edit?"Update Course":"Add Course"}
              </button>
            </div>
          </form>
          {apiError && <p className="text-red-600 text-center mt-4">{apiError}</p>}
        </div>
      </div>
      {loading && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}
        {showModal && (
        <Modal
          title="Success"
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
 
  );
};

export default AddCourse;