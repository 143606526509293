import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PageNameAndDate from "../../utilities/PageNameAndDate";
import CircularProgress from "@mui/material/CircularProgress";

const StudentDetail = () => {
  const { id } = useParams(); // Extract studentId from URL
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/student/${id}`, { withCredentials: true })
        .then((response) => {
          setLoading(false)
          console.log(response.data)
          setStudent(response.data); // Updated to reflect the correct data structure
        })
        .catch((error) => {
          setLoading(false)
          //console.error("Error fetching student details:", error);
        });
    }
  }, [id]);

  const formatDateToLocal = (date) => {
    return new Date(date).toLocaleDateString(); 
  };

  return(
  
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full relative">
        <div className="w-full p-4 md:p-6">
          <PageNameAndDate pageName={"Student"} />
        </div>
        <div className=" overflow-auto h-[450px] w-full">
        <div className="text-sm flex flex-col justify-center gap-4 p-4 ml-4 md:ml-8 w-11/12 rounded-xl shadow-xl border flex-wrap">
          {/* Student Profile */}
          <div className="flex flex-col md:text-sm text-xs"> 
            <h1 className=" font-semibold text-md mb-4">Student Profile</h1>
            <div className="flex gap-4">
            {loading && (
              <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
                <CircularProgress style={{ color: "blue" }} />
              </div>
            )}
              <div className="item-center self-center">
                <AccountCircleIcon fontSize="large" sx={{ color: 'gray' }} />
              </div>
              <div className="flex flex-col">
                <p>{student?.name}</p>
                <p>{student?.email}</p>
                <p>Contact: {student?.contact}</p>
              </div>
            </div>
          </div>
          <hr />

          {/* Courses */}
          <div className="flex flex-col">
            {/* <h1 className=" font-semibold text-md">Courses</h1> */}
            {student?.courses.length > 0 ? (
              student?.courses.map((course, index) => (
                <div key={index} className="flex justify-between mt-4 md:text-sm text-xs">
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Course Name</h2>
                    <p className="md:w-72 2xl:w-96 w-28">{course?.courseTitle}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Price</h2>
                    <p className="w-16">${course?.coursePrice}</p>
                  </div>
                  
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Duration</h2>
                    <p>{course?.courseDuration}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h2 className="font-semibold">End Date</h2>
                      <p>{formatDateToLocal(course?.endDate)}</p> {/* Converts endDate to local time */}
                    </div>
                </div>
              ))
            ) : (
              <p>No courses found.</p>
            )}
          </div>
          <hr />

          {/* Simulators */}
          <div className="flex flex-col md:text-sm text-xs">
            {/* <h1 className=" font-semibold text-md">Simulators</h1> */}
            {student?.simulatorsPurchased.length > 0 ? (
              student?.simulatorsPurchased.map((simulator, index) => (
                <div key={index} className="flex justify-between mt-4 ">
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Simulator Name</h2>
                    <p className="md:w-72 2xl:w-96 w-28">{simulator?.simulatorTitle}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Price</h2>
                    <p className="w-16">${simulator?.simulatorPrice}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h2 className="font-semibold">Duration</h2>
                    <p className="">{simulator?.simulatorDuration}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h2 className="font-semibold">End Date</h2>
                      <p>{formatDateToLocal(simulator?.endDate)}</p> {/* Converts endDate to local time */}
                    </div>
                </div>
              ))
            ) : (
              <p>No simulators found.</p>
            )}
          </div>
          <hr />
        </div>
      </div>
      </div>
    </div>
   
  );
};

export default StudentDetail;
