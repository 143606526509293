import React from 'react';
import { Email, Phone, Facebook, Twitter, Instagram, WhatsApp } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-footer_blue pt-20 pb-4">
      <div className="container mx-auto px-4">
        {/* Footer Sections */}
        <div className="flex flex-col md:flex-row md:justify-between gap-8 md:gap-20">
          {/* Company Info */}
          <div className="flex flex-col w-full items-center md:w-1/3">
            <h2 className="text-xl font-semibold mb-4 text-white text-center md:text-left md:pr-4">Company Info</h2>
            <ul className="space-y-4 text-gray-200 text-sm text-center md:text-left">
              <li><a href="/about-us" className="hover:underline">About Us</a></li>
              <li><a href="/Our-Mission" className="hover:underline">Our Mission</a></li>
              <li><a href="/Terms-and-condition" className="hover:underline">Terms and Conditions</a></li>
              <li><a href="/privacy-policy" className="hover:underline">Privacy Policy</a></li>
            </ul>
          </div>
          {/* Resources */}
          <div className="flex flex-col w-full items-center md:w-1/3 ">
            <h2 className="text-xl font-semibold mb-4 text-white text-center pr-0 sm:pr-20 2xl:pr-36">Featured Courses</h2>
            <div className="flex flex-col gap-4 w-4/5 md:ml-6  text-gray-200 text-sm text-center md:text-left">
              <a href='/courses/courseDetail/courses/course/PMI-PMP%20Course%20Incl.%20Simulator'>PMI PgMP Incl Simulator</a>
              <a href='/courses/courseDetail/courses/course/PMI-PgMP%20Course%20Incl.%20Simulator'>PMI PgMP Incl Simulator</a>
              <a href='/courses/courseDetail/courses/course/PMI-PgMP%20Application%20Submission'>PMI-PgMP Application Submission</a>
              <a href='/courses/courseDetail/courses/course/Introduction%20to%20Project%20Management'>Intro to Project Management</a>
            </div>
          </div>
          {/* Contact Info */}
          <div className="flex flex-col w-full items-center md:w-1/3">
            <h2 className="text-xl font-semibold mb-4 text-white text-center md:text-left md:pr-14">Contact Info</h2>
            <ul className="space-y-8 text-gray-200 text-sm text-center md:text-left md:pl-16">
              <li className="flex items-center justify-center md:justify-start">
                <Email className="mr-2 text-yellow-300" />
                <a href="mailto:test@gmail.com" className="hover:underline">MndE.Consultants@gmail.com</a>
              </li>
              {/* <li className="flex items-center justify-center md:justify-start">
                <Phone className="mr-2 text-green-300" />
                <a href="tel:+92123123123" className="hover:underline">+92123123123</a>
              </li> */}
              <li className="flex justify-center md:justify-start space-x-4 mt-2">
                <a href="#" className="hover:text-gray-300 text-blue-500">
                  <Facebook />
                </a>
                <a href="#" className="hover:text-gray-300 text-blue-400">
                  <Twitter />
                </a>
                <a href="#" className="hover:text-gray-300 text-pink-500">
                  <Instagram />
                </a>
                <a href="#" className="hover:text-gray-300 text-green-500">
                  <WhatsApp />
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Copyright and Powered by */}
        <div className="text-center mt-12 text-gray-200 text-sm">
          <p>© Copyright 2024, All Rights Reserved</p>
          <p className='pt-4'>
          Designed and Developed by{' '}
            <a href="https://muhammadabdullah9999.github.io/Portfolio/" className="text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer">
              Muhammad Abdullah
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
