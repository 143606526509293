import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Ensure this import is correct
import Modal from "./Modal"; // Adjust path as needed

function PageNameAndDate({ pageName, showBackButton, backLink, unsavedChanges }) {
  const [currentDate, setCurrentDate] = useState("");
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    setCurrentDate(formattedDate);
  }, []);

  const handleNavigation = (to) => {
    if (unsavedChanges) {
      setPendingNavigation(() => () => navigate(to));
      setShowUnsavedChangesModal(true);
    } else {
      navigate(to);
    }
  };

  const closeUnsavedChangesModal = () => {
    setShowUnsavedChangesModal(false);
  };

  const confirmUnsavedChanges = () => {
    closeUnsavedChangesModal();
    if (pendingNavigation) pendingNavigation();
  };

  const saveAndNavigate = async () => {
    // Implement your save changes logic here
    // await saveChanges();
    closeUnsavedChangesModal();
    if (pendingNavigation) pendingNavigation();
  };

  return (
    <>
      {showUnsavedChangesModal && (
        <Modal
          title="Unsaved Changes"
          message="You have unsaved changes. Are you sure you want to proceed?"
          onClose={closeUnsavedChangesModal}
          onConfirm={confirmUnsavedChanges}
          onSave={saveAndNavigate}
        />
      )}

      <div className="flex items-center justify-between border-b-2 mb-4">
        {showBackButton && backLink && (
          <div className="flex gap-2">
            <div
              role="button"
              onClick={() => handleNavigation(backLink)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <ArrowBackIcon />
            </div>
            <h1 className="text-3xl font-medium">{pageName}</h1>
          </div>
        )}
        {!showBackButton && (
          <h1 className="text-3xl font-medium">{pageName}</h1>
        )}
        <p className="text-gray-500 text-sm hidden sm:block">{currentDate}</p>
      </div>
    </>
  );
}

export default PageNameAndDate;
