import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Components/styles/Modal';
import axios from 'axios';
import { useCookies } from "react-cookie";
import CircularProgress from "@mui/material/CircularProgress";


const Success = () => {
  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [cookies,setCookie] = useCookies(['PMI-cart']);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, { withCredentials: true });
        if (response.data.user.userType === "student") {
          setEmail(response.data.user.email);
          setIsAuthenticated(true);
        } else {
          setEmail(cookies['user-info']?.email || '');
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setEmail(cookies['user-info']?.email || '');
        //console.log(error);
      } finally {
        setLoading(false);
      }
    };

    verifyAuth();
  }, [cookies]); // Removed email from dependency array to avoid loop

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');

      if (sessionId && email) {
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/payment/complete?session_id=${sessionId}`);
          const result = await response.json();

          if (response.ok) {
            //console.log('Payment result:', result);
            //console.log('PMI-cart:', cookies['PMI-cart']);
            const pmiCart = cookies['PMI-cart'] || [];
            const updatedCourseNames = result.courseName.map(courseName => {
              const matchingCourse = pmiCart.find(course => course.title === courseName);
              const startDate = matchingCourse ? matchingCourse.startDate : new Date();
//console.log(startDate)
              return {
                courseName,
                startDate: new Date(Date.parse(`${startDate} UTC`)).toISOString().replace('Z', '+00:00')
              };
            });
// console.log(result);
            result.courseName = updatedCourseNames;
            result.email = email;
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/payment/setPaymentDetails`, result);

            setCookie('PMI-cart', [], { path: '/' });
            setPaymentDetails(result);
            setShowModal(true);
          } else {
            setError('There was an error processing your payment.');
          }
        } catch (error) {
          console.error('Error fetching payment result:', error);
          setError('There was an error processing your payment.');
        } finally {
          setLoading(false);
        }
      }
    };

    if (email) {
      fetchData();
    }
  }, [email]); // email is necessary for fetching payment details

  const handleCloseModal = () => {
    setShowModal(false);
    if (isAuthenticated) {
      navigate('/student/dashboard');
    } else {
      navigate('/register', { state: { email } });
    }
  };

  return (
    <div>
      {loading ? (
       
       
       
        <div className="absolute inset-0  z-10 flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      
      
  
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {showModal && (
            <Modal
              message={`Thank you for your purchase! You have successfully paid ${paymentDetails.price} for the course(s): ${paymentDetails.courseName.map(course => course.courseName).join(', ')}.`}
              onClose={handleCloseModal}
              title={"Payment Successful"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Success;
