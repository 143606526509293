import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Modal from "../styles/Modal";
import CircularProgress from "@mui/material/CircularProgress"; // Material UI loader

const VerifyEmail = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const requestSent = useRef(false); // Ref to track if the request was sent

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/auth/verify-email?token=${token}`
        );
        setMessage(response.data.message);
        setLoading(false); // Stop the loader
        setShowModal(true); // Show modal when verified
      } catch (err) {
        // console.error(err);
        setError(err.response?.data?.message || "An error occurred");
        setMessage(err.response.data.message);
        setLoading(false); // Stop the loader
        setShowModal(true);
      }
    };

    // Check if token is available and request hasn't been sent
    if (token && !requestSent.current) {
      requestSent.current = true; // Mark request as sent
      verifyEmail();
    }
  }, [token]);

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    navigate("/login"); // Redirect to login
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {loading ? (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      ) : (
        <>
          {showModal && (
            <Modal
              title={error ? "Verification Failed" : "Verification Success"}
              message={error ? error : message}
              onClose={handleModalClose} // Close button will redirect to login
            />
          )}
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
