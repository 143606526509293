import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const InstructorSimulatorDetail = () => {
  const { id } = useParams();
  const [simulator, setSimulator] = useState(null);
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false); // Dialog state for adding new module
  const [newModuleTitle, setNewModuleTitle] = useState(""); // New module title
  const [newModuleMaxTime, setNewModuleMaxTime] = useState(""); // New module max time
  const [newModuleType, setNewModuleType] = useState(""); // New module type (test or training)
  const [titleError, setTitleError] = useState("");
  // const [maxTimeError, setMaxTimeError] = useState("");
  // const[questionsLimit,setQuestionsLimit]=useState('');
  // const[questionsLimitError,setQuestionsLimitError]=useState('');
  const [typeError, setTypeError] = useState(""); // Error state for type
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null); 
  const navigate = useNavigate();

  

  const fetchSimulatorData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/getSimulatorModules/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setSimulator(response.data);
        setPrice(response.data.price || "");
        setDuration(response.data.duration || "");
      })
      .catch((error) => {
        //console.error("Error fetching simulator details:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSimulatorData();
  }, [id, moduleToDelete]);

  const handleModuleClick = (moduleId, moduleTitle) => {
    navigate(`/instructor/dashboard/simulator/detail/module/${id}`, { state: { title: moduleTitle } });
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleSaveChanges = () => {
    setSaving(true);
    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/updateSimulator/${id}`, {
        price,
        duration,
        modules: simulator.modules,
      }, {
        withCredentials: true,
      })
      .then((response) => {
        //console.log("Simulator updated successfully:", response.data);
      })
      .catch((error) => {
        //console.error("Error updating simulator:", error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleAddModuleClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewModuleTitle("");
    setNewModuleMaxTime("");
    setNewModuleType(""); // Reset module type
    setTitleError("");
    // setMaxTimeError("");
    setTypeError(""); // Reset type error
  };
  useEffect(()=>{

  },[moduleToDelete])
  const handleCreateModule = () => {
    let valid = true;
  
    // Validate the module title (must start with a letter)
    if (!/^[A-Za-z]/.test(newModuleTitle)) {
      setTitleError("Module title must start with a letter.");
      valid = false;
    } else {
      setTitleError("");
    }
  
    // Check for duplicate title
    if (simulator.modules.some(module => module.title.toLowerCase() === newModuleTitle.toLowerCase())) {
      setTitleError("Module title must be unique.");
      valid = false;
    }
  
    // Validate the max time only if module type is "test"
    // if (newModuleType === "test" && newModuleMaxTime <= 0) {
    //   setMaxTimeError("Max time must be a positive number.");
    //   valid = false;
    // } else {
    //   setMaxTimeError("");
    // }
  
    // Validate the module type (must be selected)
    if (!newModuleType) {
      setTypeError("Please select the module type.");
      valid = false;
    } else {
      setTypeError("");
    }
  
    // Validate the questions limit (must be a positive integer)
    // const questionsLimitNum = Number(questionsLimit);
    // if (newModuleType === "test") {
    //   if (isNaN(questionsLimitNum) || questionsLimitNum <= 0) {
    //     setQuestionsLimitError("Question limit must be a positive integer.");
    //     valid = false;
    //   } else {
    //     setQuestionsLimitError("");
    //   }
    // }
  
    // If all fields are valid, proceed with creating the module
    if (valid) {
      const newModule = {
        title: newModuleTitle,
        // maxTime: newModuleType === "test" ? Number(newModuleMaxTime) : null, // Only set maxTime for "test"
        type: newModuleType,
        // questionsLimit: questionsLimitNum, // Set the number of questions based on the input
      };
  
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/addModule/${id}`, newModule, {
          withCredentials: true,
        })
        .then((response) => {
          fetchSimulatorData();
          handleDialogClose();
        })
        .catch((error) => {
          //console.error("Error adding new module:", error);
        });
    }
  };
  
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);

    setModuleToDelete(null); 
  };

  const handleDeleteModule = () => {
    setDeleteDialogOpen(false);
//console.log(moduleToDelete)
    if (moduleToDelete) {
      setDeleteDialogOpen(false);
      axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/deleteModule/${moduleToDelete}`, {
        data: { simulatorId: simulator.simulatorId,moduleId:moduleToDelete }, // Send moduleId in the request body
        withCredentials: true,
        })
        .then((response) => {
          // Update the simulator state to remove the deleted module
          //console.log(response);
          setSimulator((prevState) => ({
            ...prevState,
            modules: prevState.modules.filter((module) => module._id !== moduleToDelete),
          }));
          handleDeleteDialogClose();
        })
        .catch((error) => {
          //console.error("Error deleting module:", error);
        });
    }
  };
  

  if (loading) {
    return (
      <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
        <CircularProgress style={{ color: "blue" }} />
      </div>
    );
  }

  return (
  
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full relative">
        {/* <div className="relative w-full p-6 mt-12"> */}
        <div className="w-full p-4 md:p-6">
        <PageNameAndDate
            pageName={simulator?.simulatorTitle}
           
          />
        </div>        
          <div className="mt-4">     
            <div className="bg-white shadow-md rounded-lg p-4 mb-4 text-sm">
              <div className="flex justify-between">
                <h2 className="text-md font-semibold mb-2">Simulator Details</h2>
                <div className="flex justify-end">
                  <button
                    onClick={handleAddModuleClick}
                    className=" bg-green-500 text-white rounded-lg p-2"
                  >
                    Add Module
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Price:</label>
                <input
                  type="text"
                  value={price}
                  onChange={handlePriceChange}
                  className="border rounded-lg p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Duration:</label>
                <input
                  type="text"
                  value={duration}
                  onChange={handleDurationChange}
                  className="border rounded-lg p-2 w-full"
                />
              </div>
              <button
                onClick={handleSaveChanges}
                className="bg-blue-500 text-white rounded-lg p-2"
              >
                {saving ? (
                  <CircularProgress size={24} style={{ color: "white" }} />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>

            <div className="mt-4">
            {simulator?.modules?.map((module, index) => (
  <div
    key={index}
    className="bg-white shadow-md rounded-lg p-4 mb-4 cursor-pointer flex justify-between items-center"
    onClick={() => handleModuleClick(module._id, module.title)}
  >
    <div>
      <h2 className="text-lg font-semibold mb-2">{module.title}</h2>
      <p className="text-sm">Number of Questions: {module.numberOfQuestions}</p>
    </div>
    <button onClick={(e) => {
        e.stopPropagation(); // Prevent the onClick of the div from firing
        setModuleToDelete(module.moduleId);
        setDeleteDialogOpen(true);
      }} className="text-red-500">
      <DeleteIcon />
    </button>
  </div>
))}
<Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
  <DialogTitle>Confirm Deletion</DialogTitle>
  <DialogContent>
    <p>Are you sure you want to delete this module?</p>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={handleDeleteModule} color="primary">
      Delete
    </Button>
  </DialogActions>
</Dialog>

            </div>
          </div>
        {/* </div> */}
      </div>

      {/* Dialog for adding new module */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add New Module</DialogTitle>
        <DialogContent>
  <TextField
    label="Module Title"
    value={newModuleTitle}
    onChange={(e) => setNewModuleTitle(e.target.value)}
    error={!!titleError}
    helperText={titleError}
    fullWidth
    margin="normal"
  />

  {/* Conditionally render "Max Time" field only if the module type is "test" */}
  {/* {newModuleType === "test" && (
  <TextField
    label="Max Time (in minutes)"
    type="text" // Change to "text"
    value={newModuleMaxTime}
    onChange={(e) => {
      const value = e.target.value;
      // Allow empty string or positive integers only
      if (value === "" || /^[1-9]\d*$/.test(value)) {
        setNewModuleMaxTime(value);
      }
    }}
    error={!!maxTimeError}
    helperText={maxTimeError}
    fullWidth
    margin="normal"
  />
)} */}
{/* {newModuleType === "test" && (
  <TextField
    label="Question Limit"
    type="text" // Change to "text"
    value={questionsLimit}
    onChange={(e) => {
      const value = e.target.value;
      // Allow empty string or positive integers only
      if (value === "" || /^[1-9]\d*$/.test(value)) {
        setQuestionsLimit(value);
      }
    }}
    error={!!questionsLimitError}
    helperText={questionsLimitError}
    fullWidth
    margin="normal"
  />
)} */}

  {loading && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}
  {/* Module Type Dropdown */}
  <FormControl fullWidth margin="normal" error={!!typeError}>
    <InputLabel>Module Type</InputLabel>
    <Select
      value={newModuleType}
      onChange={(e) => setNewModuleType(e.target.value)}
      fullWidth
    >
      <MenuItem value="test">Test</MenuItem>
      <MenuItem value="training">Training</MenuItem>
    </Select>
    {typeError && <p style={{ color: "red" }}>{typeError}</p>}
    
  </FormControl>
</DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateModule} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
 
  );
};

export default InstructorSimulatorDetail;
