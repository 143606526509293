import React, { useState, useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";
import Navbar from "../styles/Navbar";
import Footer from "../LandingPage/Footer";
import axios from "axios";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import './paypal.css'
function Payment() {
  const initialOptions = {
    "client-id":
      "AZtk42dSQd7Q3ufl5sDSscd2KoiJVf3k0_sI2IzSaGOIaMYNDi_rCz0kGTztBbZoYiaGBxuAiJzwtG4T",
    "disable-funding": "card,credit,paylater",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const [cookies, setCookie] = useCookies(["PMI-cart", "user-info"]);
  const [setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [email, setEmail] = useState(cookies["user-info"]?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(
    cookies["user-info"]?.phoneNumber || ""
  );
  const [errors, setErrors] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies["user-info"]) {
      setShowDialog(false);
    }
    //console.log(cookies["PMI-cart"]);
  }, [cookies]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/verifyAuth`, { withCredentials: true })
      .then((response) => {
        if (response.data.user.userType === "student") {
          setEmail(response.data.user.email);
          setIsAuthenticated(true);
          setShowDialog(false); // Hide dialog if authenticated
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        setIsAuthenticated(false);
        //console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^\d{8,15}$/.test(phoneNumber)) {
      newErrors.phoneNumber =
        "Phone number is invalid. It should be between 8 and 15 digits.";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDialogSubmit = () => {
    if (validate()) {
      setCookie("user-info", { email, phoneNumber }, { path: "/" });
      setShowDialog(false);
    }
  };
  const cartItems = cookies["PMI-cart"]?.map((item) => ({
    name: item.title,
    unit_amount: {
      currency_code: "USD",
      value: item.price,
    },
    quantity: "1",
  }));

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/payment/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cartItems }),
      });
      const data = await response.json();
      //console.log(data.url);
      window.location.href = data.url;
    } catch (error) {
      //console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (isAuthenticated) {
      navigate("/student/dashboard");
    } else {
      navigate("/register", { state: { email } });
    }
  };

  return (
    <div>
      {showDialog && !isAuthenticated && (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="md:w-1/2 w-11/12 h-64 bg-gray-200 p-4 flex flex-col shadow-2xl border rounded-xl">
            <div className="flex flex-col gap-4">
              <p>Please Enter following credentials to proceed</p>
              <input
                type="email"
                placeholder="Enter Email"
                className="border p-2 rounded-lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-red-500">{errors.email}</p>}
              <input
  type="text" // Keep the input type as 'text' to allow complete control over the input
  placeholder="Enter Phone Number"
  className="border p-2 rounded-lg"
  value={phoneNumber}
  onChange={(e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Only allow digits (regex that matches only digits)
      setPhoneNumber(value);
    }
  }}
/>
{errors.phoneNumber && (
  <p className="text-red-500">{errors.phoneNumber}</p>
)}

              <button
                onClick={handleDialogSubmit}
                className="px-2 py-1 bg-blue-500 text-white rounded-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <Navbar textColor="text-blue-800" />
      <div className="min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="container mx-auto p-4">
          <div className="bg-white mb-32 shadow-md rounded-lg p-6  flex flex-col lg:flex-row gap-6">
            {/* Left Side: Cart Items */}
            <div className="flex-1">
              <h1 className="text-2xl font-bold mb-4">Shopping Cart</h1>
              {cookies["PMI-cart"]?.map((item, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-gray-700">Price: ${item.price}</p>
                  <p className="text-gray-700">Duration: {item.duration}</p>
                  <p className="text-gray-700">
                    Starting Date: {item.startDate}
                  </p>
                </div>
              ))}
            </div>

            {/* Right Side: Payment Options */}
            <div className="flex-1 flex flex-col gap-4 paypal-button-container">
              {(!showDialog|| isAuthenticated) &&  (
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    style={{
                      shape: "rect",
                      layout: "vertical",
                      height: 40,
                      width: "100%",
                      tagline: false,
                    }}
                    fundingSource={FUNDING.PAYPAL}
                    createOrder={async () => {
                      try {
                        setLoading(true);
                        const response = await fetch(
                          `${process.env.REACT_APP_SERVER_URL}/payment/api/orders`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ cart: cartItems }),
                          }
                        );

                        const orderData = await response.json();
                        // console.log(orderData);
                        setLoading(false);

                        if (orderData.id) {
                          return orderData.id;
                        } else {
                          const errorDetail = orderData?.details?.[0];
                          const errorMessage = errorDetail
                            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                            : JSON.stringify(orderData);

                          throw new Error(errorMessage);
                        }
                      } catch (error) {
                        // console.error(error);
                        // setMessage(
                        //   `Could not initiate PayPal Checkout...${error}`
                        // );
                        setLoading(false);
                        return null; // Return null to prevent further processing
                      }
                    }}
                    onApprove={async (data, actions) => {
                      try {

                        const response = await fetch(
                          `${process.env.REACT_APP_SERVER_URL}/payment/api/orders/${data.orderID}/capture`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        );

                        const orderData = await response.json();
                        const transaction = await orderData.purchase_units[0]
                          .payments.captures[0];

                        const price = `${transaction.amount.value} ${transaction.amount.currency_code}`;
                        const paidStatus ="PAID"
                          // transaction.status === "COMPLETED"
                          //   ? "PAID"
                          //   : "FAILED";
                        const paymentId = transaction.id;
                        const dateTime = new Date(transaction.create_time).toISOString();

                        // const paymentType = transaction.amount.currency_code;

                        // Mapping the start date from cookies['PMI-cart']
                        const pmiCart = cookies["PMI-cart"]
                          ? cookies["PMI-cart"]
                          : [];
                        const updatedCourseNames = cartItems.map(
                          (courseItem) => {
                            const matchingCourse = pmiCart.find(
                              (course) => course.title === courseItem.name
                            );
                            const startDate = matchingCourse
                              ? matchingCourse.startDate
                              : new Date();

                            return {
                              courseName: courseItem.name,
                              startDate: new Date(
                                Date.parse(`${startDate} UTC`)
                              )
                                .toISOString()
                                .replace("Z", "+00:00"), // Formatting the date
                            };
                          }
                        );
                        // Final formatted object
                        const paymentData = {
                          courseName: updatedCourseNames,
                          dateTime,
                          email: email,
                          paidStatus,
                          paymentId,
                          paymentType:"PayPal",
                          price,
                        };

                        // console.log(paymentData);
                        try {
  await axios.post(`${process.env.REACT_APP_SERVER_URL}/payment/setPaymentDetails`, paymentData);
  setCookie('PMI-cart', [], { path: '/' });  // Ensure this runs after the successful API call
  setShowModal(true);
} catch (error) {
  //console.error(error);
}

                      } catch (error) {
                        //console.error(error);
                        setMessage(`Payment failed: ${error.message}`);
                      }
                    }}
                    onError={(err) => {
                      //console.error("PayPal Error:", err);
                      setMessage(`PayPal error: ${err.message}`);
                      setLoading(false);
                    }}
                  />
                </PayPalScriptProvider>
              )}

              <div className="mt-8">
                <button
                  className="w-full px-4 py-2 bg-blue-600 text-white rounded-md"
                  onClick={handleCheckout}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faWallet} /> Pay with Credit/Debit/others
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modal for Payment Success */}
      {showModal && (
        <Modal onClose={handleModalClose}>
          <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Payment Successful!</h2>
            <p>Your payment has been processed successfully.</p>
            {/* <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
              onClick={handleModalClose}
            >
              OK
            </button> */}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Payment;


