import React, { useEffect, useState } from "react";
import Sidebar from "../../utilities/Sidebar";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import { useCookies } from "react-cookie"; // Import the useCookies hook

const Profile = () => {
  const params = useParams();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["userName"]); // Use useCookies hook


  

  useEffect(() => {
  
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/${params.type}/dashboard/profile`, {
          withCredentials: true,
        })
        .then((response) => {
  
          setProfile(response.data);
        })
        .catch((error) => {
          //console.error("Error fetching profile:", error);
        })
        .finally(() => {
          setLoading(false);
        });

  }, [ params.type]);

  const handleEditProfile = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!profile.name || !profile.email || !profile.contact) {
      setErrorMessage("Name, Email, and Contact fields cannot be empty.");
      return;
    }

    // Regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(profile.email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const contactNumberRegex = /^[0-9]{10,15}$/;
    if (!contactNumberRegex.test(profile.contact)) {
      setErrorMessage("Please enter a valid contact number (10-15 digits).");
      return;
    }

    // Password validation: must be at least 6 characters
    if (newPassword && newPassword.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    // Check if new password and confirm password match
    if (newPassword && newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    if (newPassword) {
      setPasswordDialogOpen(true); // Open confirmation dialog for password change
      return;
    }

    submitProfile();
  };

  const submitProfile = () => {
    setErrorMessage("");

    const updatedProfile = {
      name: profile.name,
      email: profile.email,
      contact: profile.contact,
      newPassword,
    };

    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/${params.type}/dashboard/profile`, updatedProfile, {
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);
        setSuccessDialogOpen(true); // Show success dialog

        // Set userName cookie when profile name is updated
        setCookie("userName", profile.name, {
          path: "/",
          expires: new Date(Date.now() + 604800000), // Cookie expires in 7 days
        });
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error updating profile:", error);
      });
  };

  const handlePasswordChangeConfirm = () => {
    setPasswordDialogOpen(false);
    submitProfile(); // Proceed with profile submission after confirming password change
  };

  return(
<div>
    <div className="flex flex-col md:flex-row h-screen">
      {cookies.userType === 'student' ? (
        <div className="md:fixed flex flex-col md:flex-row md:h-screen">
          <Sidebar />
        </div>
      ) : (
        <div className="md:fixed flex flex-col md:flex-row md:h-screen ">
          <InstructorSidebar />
        </div>
      )}
    
      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full relative">
        <div className="w-full p-4 md:p-6 ">
          <PageNameAndDate pageName={"Profile"} />

          <div className="flex justify-center rounded-md w-full my-3">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
              <h2 className="text-xl font-semibold mb-4 text-center">
                Edit Profile
              </h2>
              <form onSubmit={handleEditProfile}>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={profile?.name || ""}
                    onChange={(e) =>
                      setProfile({ ...profile, name: e.target.value })
                    }
                    className="w-full p-2 text-sm border border-gray-300 rounded-lg shadow-sm"
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={profile?.email || ""}
                    onChange={(e) =>
                      setProfile({ ...profile, email: e.target.value })
                    }
                    className="w-full p-2 text-sm border border-gray-300 rounded-lg shadow-sm"
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor="contact"
                  >
                    Contact
                  </label>
                  <input
                    type="text"
                    id="contact"
                    value={profile?.contact || ""}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      setProfile({ ...profile, contact: numericValue });
                    }}
                    className="w-full p-2 text-sm border border-gray-300 rounded-lg shadow-sm"
                    inputMode="numeric"
                  />
                </div>

                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 rounded-lg shadow-sm"
                  />
                </div>
                <div className="mb-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1"
                    htmlFor="confirmPassword"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 rounded-lg shadow-sm"
                  />
                </div>

                {errorMessage && (
                  <div className="text-red-500 text-sm mb-3">{errorMessage}</div>
                )}
                {loading && (
        <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      )}
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white p-2 rounded-lg font-semibold text-sm hover:bg-blue-600 transition duration-300"
                >
                  Edit Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Success Dialog */}
      <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
        <DialogTitle>Profile Updated</DialogTitle>
        <DialogContent>
          <p>Your profile has been successfully updated!</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Password Confirmation Dialog */}
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Confirm Password Change</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to change your password?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePasswordChangeConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>

  );
};

export default Profile;
