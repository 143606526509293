import React, { useEffect, useState } from "react";
import StudentCard from "./StudentCard";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import CircularProgress from "@mui/material/CircularProgress";

const StudentsList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

 
  useEffect(() => {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/students/`, { withCredentials: true })
        .then((response) => {
          //console.log(response.data)
          setLoading(false);
          setStudents(Array.isArray(response.data.students) ? response.data.students : []);
        })
        .catch((error) => {
          setLoading(false);
          //console.error("Error fetching students:", error);
        });
    
  }, []);

  // Filter students based on search query
  const filteredStudents = students.filter(student =>
    student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    student.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return(
   
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full relative">
        <div className="w-full px-6 pb-6 md:pt-6 pt-2 ">
          <PageNameAndDate pageName={"Students"} />

          <div className="flex flex-col gap-4">
            <input
              type="text"
              placeholder="Search students..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="md:w-1/3 w-4/5 p-2 border rounded-md"
            />
          </div>
        </div>

        {loading && (
          <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
            <CircularProgress style={{ color: "blue" }} />
          </div>
        )}

        <div className="flex justify-center gap-8 h-full overflow w-full p-4 flex-wrap">
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student, index) => (
              <div key={index} className="w-72">
                <StudentCard
                  Id={index + 1}
                  name={student.name}
                  email={student.email}
                  contact={student.contact}
                  _id={student._id}
                />
              </div>
            ))
          ) : (
            <p>No students available</p>
          )}
        </div>
      </div>
    </div>
 
  );
};

export default StudentsList;
