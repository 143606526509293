import React from 'react';
import Navbar from './styles/Navbar';
import Footer from './LandingPage/Footer';

const TermsAndConditions = () => {
  return (
    <div>
        <Navbar textColor='text-blue-800'></Navbar>
    <div className="container mx-auto p-6 pt-8 pb-12 bg-gray-100">
      <h1 className="text-3xl text-blue-800 text-center font-bold mb-6">Terms and Conditions</h1>
      <p className="text-gray-700 mb-6">
        These Terms and Conditions govern your use of our website, services, and online courses. By accessing and using our website, you agree to these terms. Please read them carefully.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="text-gray-600 mb-6">
        By using our website, you agree to comply with these terms. If you do not agree with any part of these terms, please discontinue using the site.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">2. Course Enrollment and Payments</h2>
      <p className="text-gray-600 mb-6">
        You may purchase online courses using PayPal, Visa, Stripe, or other accepted payment methods. Once payment is processed, you will receive immediate access to the course materials. All sales are final, and refunds are not available unless explicitly stated otherwise.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">3. Intellectual Property</h2>
      <p className="text-gray-600 mb-6">
        All course content, materials, simulators, and branding are the intellectual property of MndE Consultants. You may not copy, distribute, or modify any content without express written permission.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">4. Online Meeting Conduct</h2>
      <p className="text-gray-600 mb-6">
        When participating in online meetings with instructors, you are expected to maintain professionalism. Disruptive behavior may result in account suspension or termination.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">5. Simulator Use</h2>
      <p className="text-gray-600 mb-6">
        Our simulators are provided to help users prepare for PMI certification exams. We do not guarantee that your use of the simulators will result in passing any certification exams. The simulators are for practice purposes only.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">6. Limitation of Liability</h2>
      <p className="text-gray-600 mb-6">
        We are not liable for any direct or indirect damages resulting from your use of the website, services, or course materials. This includes, but is not limited to, loss of data, business interruption, or personal injury.
      </p>

      <h2 className="text-2xl text-blue-800 font-semibold mb-4">7. Changes to Terms</h2>
      <p className="text-gray-600 mb-6">
        We reserve the right to modify these terms at any time. Changes will be posted on this page, and continued use of our website constitutes acceptance of the updated terms.
      </p>

      <p className="text-gray-600">
        If you have any questions about these Terms and Conditions, please contact us at mnde.Consultants@gmail.com.
      </p>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default TermsAndConditions;
