// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.paypal-button-container {
    z-index: 1; /* Adjust the z-index as needed */
    position: relative; /* Ensure it's positioned correctly */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Payment/paypal.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,UAAU,EAAE,iCAAiC;IAC7C,kBAAkB,EAAE,qCAAqC;EAC3D","sourcesContent":["/* styles.css */\r\n.paypal-button-container {\r\n    z-index: 1; /* Adjust the z-index as needed */\r\n    position: relative; /* Ensure it's positioned correctly */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
