import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../../utilities/Sidebar";
import PageNameAndDate from "../../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const SimulatorModuleList = () => {
  const [modules, setModules] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true); // Start with loading as true
  const { simulatorTitle } = useParams(); // Extract simulatorTitle from URL

 

  useEffect(() => {
    const fetchModules = async () => {
      if (simulatorTitle) {
        setLoading(true); // Set loading to true before fetching data
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/student/dashboard/simulators/${simulatorTitle}`,
            { withCredentials: true }
          );
          //console.log(response)
          setModules(response.data.modules); // Update state after fetching
        } catch (error) {
          //console.error("Error fetching simulators:", error);
        } 
        finally {
          setLoading(false); // Set loading to false after fetching is done
        }
      }
    };

    fetchModules();
  }, [simulatorTitle]);

  // if (isAuthenticated === null || loading) {
  //   // Show a spinner while authenticating or fetching data
  //   return (
  //     <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
  //       <CircularProgress style={{ color: "blue" }} />
  //     </div>
  //   );
  // }

 

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <Sidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full md:p-6 p-4 md:mt-4 relative">
        <PageNameAndDate pageName={"Simulator Modules"} />

        {/* Render content when loading is complete */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        {loading
 &&
 <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
        <CircularProgress style={{ color: "blue" }} />
      </div>}
          {modules.length > 0 ? (
            modules.map((module) => {
              const moduleTitle = encodeURIComponent(module.moduleName || "Unknown");
              return (
                <Link
                  key={module.moduleId}
                  to={`/student/dashboard/Simulators/modules/${simulatorTitle}/${moduleTitle}`}
                  className="relative p-6 bg-white border border-gray-200 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out hover:shadow-xl"
                >
                  <div className="absolute top-2 right-2 bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full shadow-sm">
                    {module.questionsSolved}/{module.moduleType === 'test' 
    ? (module?.questionsLimit <= module?.totalQuestions 
        ? module?.questionsLimit 
        : module?.totalQuestions || 0)
    : module?.totalQuestions || 0} Completed
                  </div>
                  <h3 className="text-xl font-bold text-gray-900">
                    {module.moduleName || "Unknown"}
                  </h3>
                  <p className="text-gray-600 mt-2">
                    Total Questions: {module.moduleType === 'test' 
    ? (module?.questionsLimit <= module?.totalQuestions 
        ? module?.questionsLimit 
        : module?.totalQuestions || 0)
    : module?.totalQuestions || 0}
                  </p>
                </Link>
              );
            })
          ) : (
            // Only show "No modules available" if loading is complete and no modules exist
            <p className="text-gray-500 text-center col-span-full mt-6">
              No modules available.
            </p>
          )}
          
        </div>
      </div>
      {/* {loading && (
            <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )} */}
    </div>
  );
};

export default SimulatorModuleList;
