import React, { useState, useEffect } from "react";
import axios from "axios";
import InstructorSidebar from "../utilities/InstructorSidebar";
import DashboardCard from "../utilities/DashboardCard";
import PageNameAndDate from "../utilities/PageNameAndDate";
import CircularProgress from "@mui/material/CircularProgress";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Modal from "../../styles/Modal";

function InstructorDashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false); 
  const [modalMessage, setModalMessage] = useState(""); 
  const [modalTitle, setModalTitle] = useState(""); 

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard`, {
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);
        setDashboardData(response.data);
        //console.log(response.data)
      })
      .catch((error) => {
        setLoading(false);
        //console.error("Error fetching dashboard data:", error);
      });
  };

  const copyToClipboard = (meetingLink) => {
    if (!meetingLink) {
      setModalTitle("Error");
      setModalMessage("There is no meeting link available!");
      setModalVisible(true);
      return;
    }
  
    navigator.clipboard
      .writeText(meetingLink)
      .then(() => {
        setModalTitle("Success");
        setModalMessage("Meeting link copied to clipboard!");
        setModalVisible(true);
      })
      .catch((err) => {
        //console.error("Failed to copy link: ", err);
        setModalTitle("Error");
        setModalMessage("Failed to copy the link!");
        setModalVisible(true);
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <InstructorSidebar />
      <div className="flex-1 flex flex-col overflow-hidden relative">
        <div className="p-4 md:p-6 md:mt-4">
          <PageNameAndDate pageName="Dashboard" />
          {loading && (
            <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            <DashboardCard label="Total Students" value={dashboardData?.totalStudents || 0} />
            <DashboardCard label="Total Courses" value={dashboardData?.totalCourses || 0} />
            <DashboardCard label="Total Simulators" value={dashboardData?.totalSimulators || 0} />
            <DashboardCard label="Meetings Scheduled" value={dashboardData?.totalUpcomingClasses || 0} />
          </div>

          <div className="dashboard mt-6 shadow-md shadow-gray-200 rounded-xl p-6 bg-gray-50 h-[34%] 2xl:h-1/2 overflow-auto">
            <h1 className="text-lg text-gray-800 font-bold mb-2 text-center">Upcoming Week Classes</h1>
            
            {/* Scrollable Table Container */}
            <div className="overflow-y-auto max-h-72"> 
              {dashboardData?.upcomingClasses?.length ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Meeting Link
                      </th>
                      <th className="px-6 py-3 pl-10 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {dashboardData.upcomingClasses.map((classItem, index) => {
                      const parseTime = (timeString) => {
                        const year = timeString.slice(0, 4);
                        const month = timeString.slice(4, 6) - 1; 
                        const day = timeString.slice(6, 8);
                        const hour = timeString.slice(9, 11);
                        const minute = timeString.slice(11, 13);
                        const second = timeString.slice(13, 15);
                        return new Date(Date.UTC(year, month, day, hour, minute, second));
                      };

                      const startTimeObj = parseTime(classItem.startTime);
                      const endTimeObj = parseTime(classItem.endTime);
                      const classDateObj = new Date(classItem.classDate);

                      const formattedDate = classDateObj.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });

                      const startTime = startTimeObj.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      const endTime = endTimeObj.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-500">
                            <div className="flex items-center gap-2">
                              <FileCopyIcon
                                className="h-5 w-5 text-blue-500 cursor-pointer hover:text-blue-700 transition"
                                onClick={() => copyToClipboard(classItem.meetingLink)}
                              />
                              <a
                                href={classItem.meetingLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block underline truncate hover:text-blue-700 transition"
                                style={{ maxWidth: '200px' }}
                              >
                                {classItem.meetingLink || "N/A"}
                              </a>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left">
                            {formattedDate}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {`${startTime} - ${endTime}`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center text-gray-600">No upcoming classes this week.</p>
              )}
            </div>
          </div>

          {modalVisible && (
            <Modal title={modalTitle} message={modalMessage} onClose={handleCloseModal} />
          )}
        </div>
      </div>
    </div>
  );
}

export default InstructorDashboard;
