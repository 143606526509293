import React from 'react';

const Cancel = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Payment Cancelled</h1>
        <p className="text-lg text-gray-700 mb-6">
          Your payment was cancelled. Please try again or contact support if you need assistance.
        </p>
        <a
          href="/"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Return to Home
        </a>
      </div>
    </div>
  );
};

export default Cancel;
