import React, { useEffect, useState } from "react";
import Sidebar from "../utilities/Sidebar";
import CourseCard from "./CourseCard";
import PageNameAndDate from "../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/student/dashboard/courses/`, {
          withCredentials: true,
        })
        .then((response) => {
          ////console.log(response)
          setCourses(
            Array.isArray(response.data.courses) ? response.data.courses : []
          );
        })
        .catch((error) => {
          //console.error("Error fetching courses:", error);
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  
  return (

    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <Sidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full relative">
        <div className="w-full p-4 md:p-6 md:mt-4">
          <PageNameAndDate pageName={"Courses"} />

          <div className="flex justify-between rounded-md w-full my-3"></div>
        </div>
{loading &&
  <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
        <CircularProgress style={{ color: "blue" }} />
       </div>
  }
        <div className="flex justify-center gap-8 h-full w-full p-4 flex-wrap">
          {courses.length > 0 ? (
            courses.map((course, index) => (
              <div key={index} className="w-72">
                <CourseCard
                  Id={index + 1}
                  courseTitle={course.title}
                  duration={course.duration}
                  mode={course.mode}
                  courseId={course.courseId}
                  userType={'student'}
                  startDate={course.startDate}
                  
                />
              </div>
            ))
          ) : (
            <p>No courses available</p>
          )}
        </div>
      </div>
    </div>

  );
};

export default Courses;
