import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import InstructorSidebar from "../../utilities/InstructorSidebar";

const ShowCourseStudents = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id ,StartDate} = useParams(); // Retrieve the course ID from the URL

  //console.log(id,StartDate)

 

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/students/getStudentsByCourse`, {
          params: { courseId: id ,startDate:StartDate}, // Send course ID as a query parameter
          withCredentials: true,
        })
        .then((response) => {
          //console.log(response)
          setStudents(
            Array.isArray(response.data.students) ? response.data.students : []
          );
        })
        .catch((error) => {
          //console.error("Error fetching students:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return (
      <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
        <CircularProgress style={{ color: "blue" }} />
      </div>
    );
  }

  return(

    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row h-screen">
        <InstructorSidebar />
      </div>

      <div className="md:ml-[268px] sm:ml-44 flex flex-col w-full md:mt-0 relative">
        <div className="w-full p-6">
          <PageNameAndDate pageName={"Course Students"} 

            showBackButton={true}
          backLink={`/instructor/dashboard/course/detail/${id}`}
          />

          <h1 className="font-bold text-xl text-center my-2">
            {students[0]?.coursesEnrolled[0]?.manualCourseId}
          </h1>

          {/* Responsive Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
              <thead>
                <tr className="border-b bg-gray-100">
                  <th className="p-4 text-left text-gray-600 text-xs sm:text-sm md:text-base">Name</th>
                  <th className="p-4 text-left text-gray-600 text-xs sm:text-sm md:text-base">Email</th>
                  <th className="p-4 text-left text-gray-600 text-xs sm:text-sm md:text-base">Contact</th>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 ? (
                  students.map((student) => (
                    <tr key={student._id} className="border-b">
                      <td className="p-4 text-gray-700 text-xs sm:text-sm md:text-base">{student.name}</td>
                      <td className="p-4 text-gray-700 text-xs sm:text-sm md:text-base">{student.email}</td>
                      <td className="p-4 text-gray-700 text-xs sm:text-sm md:text-base">{student.contact}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="p-4 text-center text-gray-600 text-xs sm:text-sm md:text-base">
                      No students found for this course.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
 
  );
};

export default ShowCourseStudents;
