import React, { useEffect, useState } from "react";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import InstructorSidebar from "../../utilities/InstructorSidebar";
import CircularProgress from "@mui/material/CircularProgress";

const Payments = () => {
  const [paymentDetail, setPaymentDetail] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
  
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/instructor/dashboard/payments/`, { withCredentials: true })
        .then((response) => {
          console.log(response)
          setLoading(false);
          setPaymentDetail(response.data);
        })
        .catch((error) => {
          setLoading(false);
          //console.error("Error fetching payments:", error);
        });

  }, []);

  // Function to safely extract price from the object
  const formatPrice = (price) => {
    if (price && typeof price === 'object') {
      return price.$numberDecimal || '0.00';
    }
    return price || '0.00';
  };

  return (
  
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed z-10 flex flex-col md:flex-row md:h-screen">
        <InstructorSidebar />
      </div>

      <div className="ml-0 md:ml-64 flex flex-col w-full p-4 md:p-6 bg-gray-50 ">
  <PageNameAndDate pageName={"Payments"} />

  <div className="w-full mt-4 p-4 bg-white shadow-md rounded-lg relative">
  {loading && (
        <div className="absolute inset-0 backdrop-filter backdrop-blur-sm z-10 flex items-center justify-center">
          <CircularProgress style={{ color: "#007bff" }} />
        </div>
      )}
    <div className="flex justify-between items-center mb-4">
      {/* <h2 className="text-xl font-semibold text-center">Payment Details</h2> */}
    </div>

    <div className="overflow-x-auto">
    <table className="w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
  <thead className="bg-gray-200">
    <tr>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/4">Email</th>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/4 md:text-xs">Course</th>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/6">Price</th>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/6">Payment Date</th>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/6">Expiry Date</th>
      <th className="border-b border-gray-300 p-3 text-left text-sm w-1/6">Payment Type</th>
    </tr>
  </thead>
  <tbody>
    {paymentDetail.map((payment, index) => (
      <tr key={index} className="hover:bg-gray-100 transition">
        <td className="border-b border-gray-300 p-3 text-sm">{payment.email}</td>
        <td className="border-b border-gray-300 p-3 text-sm md:text-xs">{payment.course}</td>
        <td className="border-b border-gray-300 p-3 text-sm">${formatPrice(payment.price)}</td>
        <td className="border-b border-gray-300 p-3 text-sm">{payment.paymentDate}</td>
        <td className="border-b border-gray-300 p-3 text-sm">{payment.expiryDate}</td>
        <td className="border-b border-gray-300 p-3 text-sm ">{payment.paymentType}</td>
      </tr>
    ))}
  </tbody>
</table>

    </div>
  </div>
</div>


    
    </div>
 
  );
};

export default Payments;
