import React, { useEffect, useState } from "react";
import Sidebar from "../../utilities/Sidebar";
import PageNameAndDate from "../../utilities/PageNameAndDate";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import './Print.css';

const PaymentsAndBilling = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
   
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/student/dashboard/payments/`, {
          withCredentials: true,
        })
        .then((response) => {
          //console.log(response.data)
          setLoading(false);
          if (Array.isArray(response.data.payments)) {
            setPayments(response.data.payments);
          } else {
            //console.error("Unexpected data format:", response.data);
            setPayments([]); // Handle unexpected format
          }
        })
        .catch((error) => {
          setLoading(false);
          //console.error("Error fetching payments:", error);
        });

  }, []);

  const handlePrint = () => {
    window.print();
  };

  // if (loading) {
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  //       <CircularProgress style={{ color: "blue" }} />
  //     </div>
  //   );
  // }

  // if (isAuthenticated === null) {
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center">
  //       <CircularProgress style={{ color: "blue" }} />
  //     </div>
  //   );
  // }

  return (
   
    <div className="flex flex-col md:flex-row h-screen">
      <div className="md:fixed flex flex-col md:flex-row md:h-screen">
        <Sidebar />
      </div>

      <div className="flex flex-col w-full md:ml-[268px] sm:ml-44 md:p-6 p-4 relative">
        <PageNameAndDate pageName={"Payments"} />
        <div className="font-bold text-xl mt-4 flex flex-col md:flex-row justify-between items-center">
          <span>Billing History</span>
          <button
            onClick={handlePrint}
            className="bg-blue-500 text-white text-base font-light px-4 py-2 md:mt-0 mt-4 rounded-2xl hover:bg-blue-600"
          >
            Print Bills
          </button>
        </div>
        {loading &&
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <CircularProgress style={{ color: "blue" }} />
      </div>}
        <div className="mt-3">
          {payments.length > 0 ? (
            <div id="printableArea" className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 relative overflow-x-auto">
              {/* Header Row */}
              <div className="hidden md:grid grid-cols-6 gap-4 font-semibold mb-2 text-gray-700">
                <div>Course Name</div>
                <div>Amount</div>
                <div>Payment Type</div>
                <div>Date</div>
                <div>Expiry Date</div>
                <div>Duration</div>
              </div>

              {/* Payment Rows */}
              <div className="space-y-4 mt-12">
                {payments.map((payment, index) => (
                  <div key={index} className="flex flex-col md:grid md:grid-cols-6 gap-4 text-sm text-gray-600">
                    <div className="md:hidden font-semibold">Course Name:</div>
                    <div>{payment.courseName}</div>

                    <div className="md:hidden font-semibold">Amount:</div>
                    <div>${payment.amount}</div>

                    <div className="md:hidden font-semibold">Payment Type:</div>
                    <div>{payment.paymentType}</div>

                    <div className="md:hidden font-semibold">Date:</div>
                    <div>{new Date(payment.dateTime).toLocaleDateString()}</div>

                    <div className="md:hidden font-semibold">Expiry Date:</div>
                    <div>
  {payment.expiryDate.map((expiry, index) => (
    <div key={index}>
      {new Date(expiry.date).toLocaleDateString()}
    </div>
  ))}
</div>

                    <div className="md:hidden font-semibold">Duration:</div>
                    <div>{payment.duration}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-lg text-gray-500">No payments found.</p>
          )}
        </div>
      </div>
    </div>

  );
};

export default PaymentsAndBilling;
